import React, { Component } from 'react';
import { Container, CustomInput, Row, Col, Spinner, Form, FormGroup, Label, Input } from 'reactstrap';
import '../css/Contactsales.css';
import contacticon from "../images/contactsale-images/Contact-sales-Main-page-final-825x340.png";
//api
import { apiConfig } from "../services/config/apiConfig";
import api from "../services/config/api";

class ContactSales extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formValues: {
                firstname: null, lastname: null,
                email: null,
                phoneno: null, product: null,
                typeofquery: null, description: null
            },
            spinner:false,
            message:false,
            validation:false
        }
    }
    onSave = async() => {
        if(this.state.formValues.firstname && this.state.formValues.lastname && this.state.formValues.email && this.state.formValues.phoneno &&
            this.state.formValues.product && this.state.formValues.typeofquery){
                this.setState({spinner:true,validation:false});
                let contactInfo = {
                    first_name: this.state.formValues.firstname,
                    last_name: this.state.formValues.lastname,
                    email: this.state.formValues.email,
                    phone: this.state.formValues.phoneno,
                    product: this.state.formValues.product,
                    type_of_query:  this.state.formValues.typeofquery,
                    description: this.state.formValues.description,
                  };
                  try {
                    await api.post(apiConfig.contact, contactInfo, {handlerEnabled: false});
                    this.setState({message:true});
                    this.setState({spinner:false});
                  } catch (error) {
                    console.log('error', error)
                    this.setState({spinner:false});
                  }
            }else{
                this.setState({validation:true})
            }
    }
    handleFormFields = (e, field) => {
        let formValues = { ...this.state.formValues }
        formValues[field] = e.target.value
        this.setState({ formValues })
    }
    render() {
        return (
            <div>
                {this.state.spinner ? <Spinner />: null}
                <Row>
                    <Col>
                        <img src={contacticon} style={{ width: '100%' }} alt="" />
                    </Col>
                    <div className="contact_caption">
                        <span>We would love to hear from you</span>
                    </div>
                </Row>
                <Container>
                    <div className="mt-5 headertext">We’re here to answer all your questions,
                    Kindly help us fill the form and we will get in touch with you shortly</div>
                    <Form className="mt-10">
                        <FormGroup>
                            <Label >First Name<Marked/></Label>
                            <Input type="text" placeholder="First Name" required name="firstname" onChange={(e) => this.handleFormFields(e, 'firstname')} value={this.state.formValues.firstname} />
                        </FormGroup>
                        <FormGroup>
                            <Label >Last Name<Marked/></Label>
                            <Input type="text" placeholder="Last Name" required name="lastname" onChange={(e) => this.handleFormFields(e, 'lastname')} value={this.state.formValues.lastname} />
                        </FormGroup>
                        <FormGroup>
                            <Label >Email<Marked/></Label>
                            <Input type="email" placeholder="Official Email" required name="email" onChange={(e) => this.handleFormFields(e, 'email')} value={this.state.formValues.email} />
                        </FormGroup>
                        <FormGroup>
                            <Label >Phone Number<Marked/></Label>
                            <Input type="text" placeholder="Phone Number"  required name="phoneno" onChange={(e) => this.handleFormFields(e, 'phoneno')} value={this.state.formValues.phoneno} />
                        </FormGroup>
                        <FormGroup>
                            <Label >Product<Marked/></Label>
                            <CustomInput type="select" placeholder="Select Product" required name="product" onChange={(e) => this.handleFormFields(e, 'product')} value={this.state.formValues.product} >
                                <option value="">Select Product</option>
                                <option>FOURA'AI Assets</option>
                                <option>FOURA'AI CRM</option>
                                <option>FOURA'AI Incidents</option>
                                <option>FOURA'AI Payroll</option>
                            </CustomInput>
                        </FormGroup>
                        <FormGroup>
                            <Label >Type of Query<Marked/></Label>
                            <CustomInput type="select" name="typeofquery" required onChange={(e) => this.handleFormFields(e, 'typeofquery')} value={this.state.formValues.typeofquery} >
                                <option value="">Select Query</option>
                                <option>Free Trial</option>
                                <option>Demo</option>
                                <option>Pricing</option>
                            </CustomInput>
                        </FormGroup>
                        <FormGroup>
                            <Label >Description</Label>
                            <Input type="textarea" placeholder="Description" required name="description" onChange={(e) => this.handleFormFields(e, 'description')} value={this.state.formValues.description} />
                        </FormGroup>
                        {this.state.validation?<FormGroup  className="texterror"  >
                            <div >Please fill the mandatory fields</div>
                        </FormGroup>:null}
                        {this.state.message ?<span style={{color:'blue'}}>Thanks for contacting us. We will get in touch with you shortly!</span>:null}
                        <FormGroup>

                            <Input className="sendbtn" type="button" value="Send" 
                                onClick={this.onSave} />
                        </FormGroup>
                    </Form>
                    <Row className="mt-10">
                        <Col className="alignCenter"><h3 style={{ color: "#3FA8E4" }}>Our Locations</h3></Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md="4" >
                            <p className="locastyle">INDIA</p>

                            <p>4i Apps Solutions Private Limited</p>
                            <p>1-1-1 , Tecci Park, OMR</p>
                            <p>Sholinganallur, Chennai – 600 119</p>
                            <p>India</p>
                            <p> + 91 44 6638 0000</p>
                            <p> + 91 44 6638 0100</p>
                        </Col>
                        <Col md="4" >
                            <p className="locastyle">SINGAPORE</p>

                            <p>4i Apps Solutions Pte. Ltd.</p>
                            <p>10 Jalan Besar</p>
                            <p># 10 – 03 Sim Lim Tower</p>
                            <p>Singapore 208787</p>
                            <p> +65 971 556 72</p>
                        </Col>
                        <Col md="4" >
                            <p className="locastyle">MALAYSIA</p>

                            <p>4i Apps Solutions SDN. BHD</p>
                            <p>Level 35-02 (East Wing),Q Sentral, 2A, Jalan</p>
                            <p>Stesen Sentral 2, KL Sentral, Kuala Lumpur</p>
                            <p>50470, Malaysia.</p>
                            <p> +60 17 7820682</p>
                        </Col>
                    </Row>
                    <p className="outset"></p>
                    <Row>
                        <Col md="4" >
                            <p className="locastyle">UAE</p>

                            <p>4i Sys Solutions</p>
                            <p>Dubai Office</p>
                            <p>123, RKM Building, Hor Al Anz East,</p>
                            <p>Abu Hail , PO BOX :236830, Dubai</p>
                            <p>United Arab Emirates</p>
                            <p>+971 4 2690072</p>
                            <p>+971 55 2632420</p>
                            <p>+971 52 9989126 (for MEA Enquiries)</p>
                        </Col>
                        <Col md="4" >
                            <p className="locastyle">OMAN</p>

                            <p>Smart Technology LLC</p>
                            <p>#Z 426, Knowledge Oasis Muscat,</p>
                            <p>Al Hail Sultanate of Oman</p>
                            <p>+ 968 95072507</p>
                        </Col>
                        <Col md="4" >
                            <p className="locastyle">QATAR</p>

                            <p>Four i Software Solutions</p>
                            <p>PO Box 18639</p>
                            <p>Doha, Qatar</p>
                            <p> + 974 44 984608</p>
                            <p> + 974 50 303956</p>
                        </Col>
                    </Row>
                    <p className="outset"></p>
                    <Row>
                        <Col md="4" >
                            <p className="locastyle">4i Apps Solutions FZC</p>

                            <p>Sharjah Office</p>
                            <p>Po Box No: 42693</p>
                            <p>Hamriyah Free Zone, Sharjah</p>
                            <p>United Arab Emirates</p>
                            <p> + 971 526159149</p>
                        </Col>

                    </Row>
                    <p className="outset"></p>
                    <Row>
                        <Col md="4" >
                            <p className="locastyle">CANADA</p>

                            <p>4i Apps Solutions INC</p>
                            <p>British Columbia Office</p>
                            <p>700 – 1199 West Hasting Street</p>
                            <p>Vancouver BC V6E 3T5 </p>
                            <p>Canada</p>
                            <p> +1 437 776 2218</p>
                        </Col>
                        <Col md="4" >
                            <p className="locastyle">USA</p>

                            <p>4i Apps Solutions LLC</p>
                            <p>12410 Milestone Center Dr</p>
                            <p>Suite 600, Germantown, MD 20876</p>
                            <p>USA</p>
                            <p> +1 301 979 1738</p>
                        </Col>
                    </Row>

                </Container>
            </div>
        )
    }
}

const Marked = () => {
    return(
      <span className="ml-2 red">*</span>
    )
  }
export default ContactSales