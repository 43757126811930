import React, { Fragment } from "react";
import BookDemo from './BookDemo';
//store
import { DemoProvider } from '../../context/DemoContext';

const DemoWrapper = () => {

  return (
    <Fragment>
      <DemoProvider>
        <BookDemo />
      </DemoProvider>
    </Fragment>
  );
};

export default DemoWrapper;
