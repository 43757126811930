import React, { Component, Fragment } from 'react';
import crmbanner from '../../images/crm-images/CRM - Inner page.png'
import crmpage from '../../images/crm-images/CRM_Final.png'
import crmpage1 from '../../images/crm-images/CRM-1.png'
import crmpage2 from '../../images/crm-images/crm.png'
import crmpage3 from '../../images/crm-images/CRM-2.png'
import crmpage4 from '../../images/crm-images/CRM-3.png'
import crmpage5 from '../../images/crm-images/CRM-4.png'
import crmpage6 from '../../images/crm-images/CRM-5.png'
import crmpage7 from '../../images/crm-images/CRM-6.png'
import crmpage8 from '../../images/crm-images/CRM-7.png'
import crmpage9 from '../../images/crm-images/CRM-8.png'
import { Container, Button ,Row, Col,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Crmcarousal from './crmcarosual';
import Pricing from '../../pages/pricingForm';
import './crm.css';

class CRM extends Component {
    state ={
        modal:false
    }
    componentDidMount(){
        window.scrollTo(0, 0);
    }

    pricing = ()=>{
        console.log("price invoked");
        this.setState({modal:true})
    }
    cancelmodal=()=>{
        console.log("cancel mo");
        this.setState({modal:false})

    }
    routeToPage = (page) => this.props.history.push(`/${page}`);
    render() {
        console.log("pricing-----------------------",this.state.modal);
        return (
           <Fragment>
               <Row>
                    <Col>
                        <img src={crmbanner} alt="" style={{width:"100%"}}/>
                        <div className="carousel_caption">
                            <span>Boost your team's sales efficiency using FOURR'AI CRM</span>
                            <div>
                                <Button className="signupBtn" style={{backgroundColor:'#D38C0F', border:`1px solid #D38C0F`}}>
                                Sign up for Free
                                </Button>
                                <Button className="demoBtn" style={{color:'#D38C0F'}}>Book a demo</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
               <Container>
                    <Row className="mt-10">
                        <Col xs={6} md={6}>
                            <h6>FOURA’AI CRM is a cloud-based CRM, which helps to strengthen business relationships with your customers and gain customer loyalty. 
                                Efficiently manage leads, contacts, pipeline, and forecast sales revenue using CRM Analytics.</h6>
                            <b>Business Benefits using FOURA’AI CRM.</b>
                                <li>Generate more leads for your business by running targeted campaigns.</li>
                                <li>Improve customer relationship with the help of contact management.</li>
                                <li>Double up your team productivity by adopting automation.</li>
                                <li>Multiply Profitability & speed up decision-making using analytics.</li>
                                <li>Enhance Mobility for the field sales team by providing mobile application.</li>
                        </Col>
                        <Col xs={6} md={6}>
                            <img style={{width:'100%'}} src={crmpage} alt="" />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={3}></Col>
                        <Col md={6}><h3>FOURA’AI CRM - Key Features</h3></Col>
                        <Col md={3}></Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={crmpage1} alt="" />
                        </Col>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset crm"> 
                                    <i class="fa fa-dashcube" /><span className="crmtitle">CRM Analytics Dashboard</span>
                                    <h6>Sales Analytics with a click. Analyze the performance of the sales team with the help of analytics based on new leads, the number of conversions, sales revenue, 
                                        Low-profit sales & high-profit sales using bar graphs & pie charts.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-trello" /><span className="crmtitle">Email Templates</span>
                                    <h6>Customize your mail using Inbuilt templates as per business needs & requirements.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-envelope" /><span className="crmtitle">Mass Email Campaigns</span>
                                    <h6>Send unlimited bulk emails directly from CRM & keep count easily. 
                                        Integrate easily with third-party mail tracking software.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-random" /><span className="crmtitle">Bulk Import & Export</span>
                                    <h6>Instantly upload a database without involving manual entry and easily download the data from CRM.</h6>
                                </div>
                        </Col> 
                    </Row>

                    <Row>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset crm"> 
                                    <i class="fa fa-bandcamp" /><span className="crmtitle">Campaigns management</span>
                                    <h6>Easily track the number of leads generated during each campaign, budget, 
                                        and generate accurate reports.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-weibo" /><span className="crmtitle">Sales Stage Automation</span>
                                    <h6>Smoothly convert the Lead status during each stage from an account, opportunity won & loss, etc.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-envelope" /><span className="crmtitle">Email to Lead</span>
                                    <h6>Customer emails will be automatically created as a Lead in CRM.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-archive" /><span className="crmtitle">Web to Lead</span>
                                    <h6>Enquires made on the website through contact forms, chatbot, and pop up forms will automatically be created as a lead in CRM.</h6>
                                </div>
                        </Col> 
                        <Col xs={6} md={6} className="mt-5">
                                <img style={{width:'100%'}} src={crmpage2} alt="" />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} md={6} className="mt-5">
                                <img style={{width:'100%'}} src={crmpage3} alt="" />
                        </Col>
                        <Col xs={6} md={6} className="mt-10">
                                <div className="fieldsset crm"> 
                                    <i class="fa fa-id-badge" /><span className="crmtitle">Lead Management</span>
                                    <h6>Create business criteria, standards to qualify leads and rank them as per the sales approach.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-bell-o" /><span className="crmtitle">Lead Conversion</span>
                                    <h6>Track converted leads regularly and download the lead conversion in the form of a report.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-qrcode" /><span className="crmtitle">Lead Auto-Assignment</span>
                                    <h6>Automatically assign leads to team members as per your defined command. Commands can be a specific region, Product, and Project.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-volume-control-phone" /><span className="crmtitle">Contact Management</span>
                                    <h6>Manage lead contact information such as name, phone number, Mail ID, Date of Birth, designation, social media profile tags in a single view.</h6>
                                </div>
                        </Col> 
                    </Row>

                    <Row>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset crm"> 
                                    <i class="fa fa-building-o" /><span className="crmtitle">Sales Process Mapping</span>
                                    <h6>Map team members with reporting managers by giving the 
                                        managers an edge to see the performance of team members.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-frown-o" /><span className="crmtitle">Sales Forecast</span>
                                    <h6>Forecast sales revenue using pie charts & bar graphs based on the 
                                        number of sales orders, yearly target & achieved target.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-cubes" /><span className="crmtitle">Sales Automation</span>
                                    <h6>Automate sales routine for Group activities instantly.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-comment-o" /><span className="crmtitle">Sales Order Creation</span>
                                    <h6>Generate quotations & sales orders using inbuilt templates and share them with customers via mail.</h6>
                                </div>
                        </Col> 
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={crmpage4} alt="" />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={crmpage5} alt="" />
                        </Col>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset crm"> 
                                    <i class="fa fa-universal-access" /><span className="crmtitle">Integrate your accounting software</span>
                                    <h6>Instantly create an invoice by integrating FOURA'AI CRM with accounting software.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-mobile" /><span className="crmtitle">Social Media Integrations</span>
                                    <h6>FOURA’AI CRM can easily be integrated with Facebook, LinkedIn, Instagram, and Twitter.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-indent" /><span className="crmtitle">Inventory management</span>
                                    <h6>Effortlessly track product details such as the number of products in stock, model, price, specifications, etc.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-eur" /><span className="crmtitle">Multi-Currency support</span>
                                    <h6>Convert currency value accurately using currency convertor for quotations & sales orders.</h6>
                                </div>
                        </Col> 
                    </Row>

                    <Row>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset crm"> 
                                    <i class="fa fa-contao" /><span className="crmtitle">Territory based Access Controls</span>
                                    <h6>Assign leads and accounts to team members based on the region allocated to them.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-calendar-o" /><span className="crmtitle">Activity Calendar</span>
                                    <h6>Easily plan your day to day activities and track your performance.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-tasks" /><span className="crmtitle">Roles & Profiles</span>
                                    <h6>Define a clear set of roles & responsibilities for team members and allocate work accordingly.</h6>
                                </div>
                        </Col> 
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={crmpage6} alt="" />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={crmpage7} alt="" />
                        </Col>
                        <Col xs={6} md={6} className="mt-10">
                                <div className="fieldsset crm"> 
                                    <i class="fa fa-flag" /><span className="crmtitle">Notifications</span>
                                    <h6>Get timely notifications for your planned tasks, meeting, emails, and follow-ups.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-book" /><span className="crmtitle">Document Management</span>
                                    <h6>Store & manage sales proposals, Presentations, Brochures, Posters, and flyers in the cloud.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-user-md" /><span className="crmtitle">Customer Support</span>
                                    <h6>Keep track of inquiries, feedback, and get notified instantly.</h6>
                                </div>
                        </Col> 
                    </Row>

                    <Row>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset crm"> 
                                    <i class="fa fa-database" /><span className="crmtitle">Data Storage</span>
                                    <h6>Data is safely stored in the AWS cloud & administered regularly.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-map-marker" /><span className="crmtitle">Bid Tracker</span>
                                    <h6>Efficiently track bids proposed, submitted, lost, and won.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-pencil-square-o" /><span className="crmtitle">Automatic software upgrade</span>
                                    <h6>Product features will be updated every year to offer the best products to customers. 
                                        Newly added features will be automatically updated in your module.</h6>
                                </div>
                        </Col> 
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={crmpage8} alt="" />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={crmpage9} alt="" />
                        </Col>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset crm"> 
                                    <i class="fa fa-building-o" /><span className="crmtitle">Customization</span>
                                    <h6>Highly customizable as per your business process flow, functionalities, Branding, & integrations.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-mobile" /><span className="crmtitle">Mobile Application</span>
                                    <h6>Mobile Application for IOS & Android.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-cubes" /><span className="crmtitle">Web-Services Integration</span>
                                    <h6>Seamlessly integrate FOURA’AI CRM with current software. Integrations are possible with any software as per your requirement.</h6>
                                </div>
                                <div className="fieldsset crm">
                                    <i class="fa fa-comment-o" /><span className="crmtitle">Branding</span>
                                    <h6>Label your CRM application in Brand Name, brand color, and Brand logo.</h6>
                                </div>
                        </Col> 
                    </Row>

                    <Row>
                        <Col md={4}></Col>
                        <Col md={4}><h3>Get to know your pricing</h3></Col>
                        <Col md={4}></Col>
                    </Row>
                    <Row>
                        <Col md={5}></Col>
                        <Col className="mt-5" md={2}><Button onClick={this.pricing} className="crmpricing">Pricing</Button></Col>
                        <Col md={5}></Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={2}></Col>
                        <Col md={8}><Crmcarousal /></Col>
                        <Col md={2}></Col>
                    </Row>
                    <Row className="mt-5 mb-5">
                        <Col md={4}></Col>
                        <Col md={2}><Button onClick={()=>this.routeToPage('sign-up-crm')} className="crmgetstarted">GET STARTED</Button></Col>
                        <Col md={2}><Button onClick={() => this.routeToPage("BookDemo")} className="crmbookademo">BOOK A DEMO</Button></Col>
                        <Col md={4}></Col>
                    </Row>
                </Container>
                <Modal isOpen={this.state.modal} toggle={this.cancelmodal} >
        <ModalHeader toggle={this.cancelmodal}>Pricing</ModalHeader>
        <ModalBody>
           <Pricing modal={this.cancelmodal} product="crm"/>
           
        </ModalBody>
      
      </Modal>
           </Fragment>
        )
    }
}
export default CRM
