import React, { Component, Fragment } from 'react';
import payrollbanner from '../../images/payroll-images/Payroll - Inner page.png'
import payrollpage from '../../images/payroll-images/PAYROLL_Final-Image.png'
import payroll1 from '../../images/payroll-images/Payroll-1.png'
import payroll2 from '../../images/payroll-images/Payroll-2.png'
import payroll3 from '../../images/payroll-images/Payroll-3.png'
import payroll4 from '../../images/payroll-images/Payroll-4.png'
import payroll5 from '../../images/payroll-images/Payroll-5.png'
import { Container, Button ,Row, Col,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Payrollcarousal from './payrollcarousal';
import './payroll.css';
import Pricing from '../../pages/pricingForm';

class Payroll extends Component {
    state ={
        model:false
    }
    componentDidMount(){
        window.scrollTo(0, 0);
      }
    pricing = ()=>{
        console.log("price invoked");
        this.setState({modal:true})
    }
    cancelmodal=()=>{
        console.log("cancel mo");
        this.setState({modal:false})

    }
    routeToPage = (page) => this.props.history.push(`/${page}`);
    render() {
        return (
           <Fragment>
                    <Row>
                        <Col><img src={payrollbanner} style={{width:"100%"}} alt="" /></Col>
                        <div className="payroll_caption">
                            <span>Why process payroll manually when you can automate using FOURA’AI Payroll?</span>
                            <div>
                                <Button className="signupBtn" style={{backgroundColor:'#147269', border:`1px solid #147269`}}>
                                Sign up for Free
                                </Button>
                                <Button className="demoBtn" style={{color:'#147269'}}>Book a demo</Button>
                            </div>
                        </div>
                    </Row>
               <Container>
                    <Row className="mt-10">
                        <Col xs={6} md={6}>
                            <h6>FOURA’AI Payroll is Global Payroll software with an intuitive and user-friendly interface. It is statutory compliant and automates the entire gamut of payroll tasks from calculating the salary of the employee, calculating statutory deductions to arriving at salary payable to the employee. 
                                FOURA’AI Payroll can be easily configured according to any country’s taxations & compliance.</h6>
                            <b>Business Benefits using FOURA’AI Payroll</b>
                                <li>Easy and quick payroll reconciliation.</li>
                                <li>Increase productivity through automations.</li>
                                <li>Efficiently manage your business across countries using multi-currency support.</li>
                                <li>Time-Saving report generation.</li>
                                <li>Accurate Calculations.</li>
                                <li>Statutory Compliance.</li>
                        </Col>
                        <Col xs={6} md={6}>
                            <img style={{width:'100%'}} src={payrollpage} alt="" />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={3}></Col>
                        <Col md={6}><h3>FOURA’AI Payroll- Key Features</h3></Col>
                        <Col md={3}></Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={payroll1} alt="" />
                        </Col>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset pay"> 
                                    <i class="fa fa-dashcube" /><span className="fieldsettitle">Dashboard</span>
                                    <h6>Attractive dashboard to analyze employees by departments, gender, grade, and age group.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-trello" /><span className="fieldsettitle">Bulk Upload capabilities</span>
                                    <h6>Upload employee master data from your Core HR within few seconds and process payroll immediately.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-random" /><span className="fieldsettitle">Report generation</span>
                                    <h6>Generate WPS files and download the salary payment details.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-universal-access" /><span className="fieldsettitle">Role-based access control</span>
                                    <h6>Assigns roles to different team members & give access based on functionality.</h6>
                                </div>
                        </Col> 
                    </Row>

                    <Row>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset"> 
                                    <i class="fa fa-bandcamp" /><span className="fieldsettitle">Employee Master</span>
                                    <h6>A master Setup where employee’s information like personal details, 
                                        past employment information, educational information, certifications & their documents can easily be stored & managed.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-weibo" /><span className="fieldsettitle">Compensation and Benefit</span>
                                    <h6>Quickly configure employee’s compensation details using element definitions for salary, allowances, statutory deductions, Loans, advances, provisions and automate Calculations in a single click. 
                                        Customize procedures for complex calculations.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-hdd-o" /><span className="fieldsettitle">Salary Automation</span>
                                    <h6>Instant Salary creation and transfer directly to the employees’ bank account.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-archive" /><span className="fieldsettitle">Generate Costing</span>
                                    <h6>Effortlessly generate accounting entries and transfer to GL with a single click once the payroll run is completed.</h6>
                                </div>
                        </Col> 
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={payroll2} alt="" />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={payroll3} alt="" />
                        </Col>
                        <Col xs={6} md={6} className="mt-10">
                                <div className="fieldsset"> 
                                    <i class="fa fa-id-badge" /><span className="fieldsettitle">Payslip automation</span>
                                    <h6>Automatic generation of Payslip using predefined templates. Generated Payslip will be shared instantly via mail to all employees.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-bell-o" /><span className="fieldsettitle">Rollback</span>
                                    <h6>Rollback entire payroll run in a click, make necessary changes and reprocess payroll run.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-qrcode" /><span className="fieldsettitle">Proration & Partial Period Calculation</span>
                                    <h6>Simple salary Calculations irrespective of the date of joining using inbuilt templates.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-floppy-o" /><span className="fieldsettitle">Backdated Increments and Assignment Revisions</span>
                                    <h6>Promotions, transfers, increments can be backdated easily with calculations and payments of the current month with differences.</h6>
                                </div>
                        </Col> 
                    </Row>

                    <Row>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset"> 
                                    <i class="fa fa-industry" /><span className="fieldsettitle">Multi-company support</span>
                                    <h6>Run group companies’ payroll in a single account with ease..</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-eur" /><span className="fieldsettitle">Multi-currency support</span>
                                    <h6>Process payroll quickly for different countries using their currency value and thereby reduce work.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-rub" /><span className="fieldsettitle">Multiple Payroll support</span>
                                    <h6>Manage payrolls smoothly for employees on different payrolls based on contract, a probation period, Temporary, and Permanent roles.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-pencil-square-o" /><span className="fieldsettitle">Automatic software upgrade</span>
                                    <h6>Product features will be updated every year to offer the best products to customers. Newly added features will be automatically updated in your module.</h6>
                                </div>
                        </Col> 
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={payroll4} alt="" />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={payroll5} alt="" />
                        </Col>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset"> 
                                    <i class="fa fa-mobile" /><span className="fieldsettitle">Mobile Application</span>
                                    <h6>Mobile application for IOS & Android.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-building-o" /><span className="fieldsettitle">Customization</span>
                                    <h6>Highly customizable as per your business requirements, functionalities, Branding, & integrations.</h6>
                                </div>
                                <div className="fieldsset">
                                    <i class="fa fa-cubes" /><span className="fieldsettitle">API Integration</span>
                                    <h6>Seamlessly integrate FOURA’AI Payroll with current software. Integrations are possible with any software as per your requirement.</h6>
                                </div>
                                <div className="fieldsset"> 
                                    <i class="fa fa-comment-o" /><span className="fieldsettitle">Branding</span>
                                    <h6>Label your CRM application in Brand Name, brand color, and Brand logo.</h6>
                                </div>  
                        </Col> 
                    </Row>

                    <Row>
                        <Col md={4}></Col>
                        <Col md={4}><h3>Get to know your pricing</h3></Col>
                        <Col md={4}></Col>
                    </Row>
                    <Row>
                        <Col md={5}></Col>
                        <Col className="mt-5" md={2}><Button onClick={this.pricing} className="payrollpricing">Pricing</Button></Col>
                        <Col md={5}></Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={2}></Col>
                        <Col md={8}><Payrollcarousal /></Col>
                        <Col md={2}></Col>
                    </Row>
                    <Row className="mt-5 mb-5">
                        <Col md={4}></Col>
                        <Col md={2}><Button onClick={()=>this.routeToPage('sign-up-payroll')} className="payrollgetstarted">GET STARTED</Button></Col>
                        <Col md={2}><Button onClick={() => this.routeToPage("BookDemo")} className="payrollbookademo">BOOK A DEMO</Button></Col>
                        <Col md={4}></Col>
                    </Row>
                </Container>
                <Modal isOpen={this.state.modal} toggle={this.cancelmodal} >
        <ModalHeader toggle={this.cancelmodal}>Pricing</ModalHeader>
        <ModalBody>
        <Pricing modal={this.cancelmodal} product="payroll"/>
        </ModalBody>
      
      </Modal>
           </Fragment>
        )
    }
}
export default Payroll
