import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button,Spinner } from 'reactstrap';
import { useLocation } from 'react-router-dom';
//api
import { apiConfig } from "../services/config/apiConfig";
import api from "../services/config/api";

const Pricing = (props) => {
  const location = useLocation();
  const [spinner, setSpinner] = useState(false);
  const [successFlag, setSuccessFlag] = useState(false);
  const [formValues, setFormValues] = useState({
    name: null, companyname: null, email: null, country: null, phonenumber: null, message: null
  });
  const [formvalidation, setformvalidation] = useState(false);

  const onSubmit = async() => {
    if (formValues ? (formValues.name && formValues.email && formValues.phonenumber && formValues.message && formValues.country && formValues.companyname) ? true : false : false) {
      setformvalidation(false);
      let pricingInfo = {
        name : formValues?.name,
        company_name : formValues?.companyname,
        email : formValues?.email,
        country: formValues?.country,
        message: formValues?.message,
        phone: formValues?.phonenumber,
        mode: signUpMode()
      }
      try {
        await api.post(apiConfig.pricing, pricingInfo, {handlerEnabled: false});
        setSpinner(false);
        setSuccessFlag(true);
      } catch (error) {
        console.log('error', error)
        setSpinner(false);
      }
    }else{
      setformvalidation(true);
    }
  }

  const signUpMode = () => {
    if(location?.pathname.includes("asset")){
      return "Assets"
    }else if(location?.pathname.includes("crm")){
      return "Crm"
    }else if(location?.pathname.includes("incidents")){
      return "Incidents"
    }else{
      return "Payroll"
    }
  }

  const handleFormFields = (e, field) => {
    let value = e.target.value;
    setFormValues(prevState => ({ ...prevState, [field]: value }));
  }
  return (
    <div>{spinner ? <Spinner />: null}
    {successFlag ?<span style={{color:'blue'}} className="mb-2" >Thanks for contacting us. We will get in touch with you shortly!</span>:null}
      <Form >
        <FormGroup>
          <Label for="examplename">Name<Marked /></Label>
          <Input type="name" name="name" onChange={(e) => handleFormFields(e, 'name')} required value={formValues.name} placeholder="Enter Name" />
        </FormGroup>
        <FormGroup>
          <Label for="examplecompanyname">Company Name<Marked /></Label>
          <Input type="name" name="companyname" onChange={(e) => handleFormFields(e, 'companyname')} value={formValues.companyname} required placeholder="Enter Company Name" />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Email<Marked /></Label>
          <Input type="email" name="email" onChange={(e) => handleFormFields(e, 'email')} value={formValues.email} placeholder="Enter Email" />
        </FormGroup>
        <FormGroup>
          <Label for="exampleSelect">Select Country<Marked /></Label>
          <Input type="select" required name="country" onChange={(e) => handleFormFields(e, 'country')} value={formValues.country} id="exampleSelect">
            <option>Select Country</option>
            <option>India</option>
            <option>Qatar</option>
            <option>Romania</option>
            <option>Indonesia</option>
            <option>Ireland</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="phonenumber">Phone Number<Marked /></Label>
          <Input type="name" name="phonenumber" onChange={(e) => handleFormFields(e, 'phonenumber')} value={formValues.phonenumber} required placeholder="Enter Phone Number" />
        </FormGroup>
        <FormGroup>
          <Label for="exampleText">Message<Marked /></Label>
          <Input type="textarea" name="message" onChange={(e) => handleFormFields(e, 'message')} value={formValues.message} required id="exampleText" />
        </FormGroup>
        {formvalidation?<FormGroup  className="texterror"  >
            <div >Please fill the mandatory fields</div>
          </FormGroup>:null}
        <FormGroup style={{textAlign:"center"}}><Button color="primary" type="button" onClick={onSubmit}>Save</Button>
          <Button color="secondary" type="button" style={{marginLeft:"24px"}} onClick={() => { props.modal() }} >Cancel</Button>
        </FormGroup>
      </Form>
    </div>
  );
}


const Marked = () => {
  return <span className="ml-2 red">*</span>;
};

export default Pricing;