import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row ,Col,Card,CardBody,Button} from 'reactstrap';
import ApplicationInfo from './ApplicationInfo';
import '../css/application.css';
const ApplicationWrapper = () => {
  
  const [crm] = useState(require("../images/home/CRM.png"));
  const [assets] = useState(require("../images/home/Assets.png"));
  const [incidents] = useState(require("../images/home/Incidents.png"));
  const [payroll] = useState(require("../images/home/Payroll.png"));
  const [easy] = useState(require("../images/home/Easy-to-use.png"));
  const [lowcost] = useState(require("../images/home/Low-cost.png"));
  const [product] = useState(require("../images/home/Product-training.png"));
  const [whitelabel] = useState(require("../images/home/White-Label-Application.png"));
  const [mobile] = useState(require("../images/home/Mobile-Application.png"));
  const [smooth] = useState(require("../images/home/Smooth-Integrations.png"));
  const [advanced] = useState(require("../images/home/Advanced-features.png"));
  const [automatic] = useState(require("../images/home/Automatic-software-upgrade.png"));
  const [highly] = useState(require("../images/home/Highly-customizable.png"));
  const [products, setProducts] = useState(0);
  const [countries, setCountries] = useState(0);
  const [features, setFeatures] = useState(0);
  const [integrations, setIntegrations] = useState(0);

  let history = useHistory();

  useEffect(()=>{
    let myInterval = setInterval(() => {
      if(products === 4){
        clearInterval(myInterval);
      }else{
        setProducts(products + 1);
      }
      if(features === 40){
        clearInterval(myInterval);
      }else{
        setFeatures(features + 1);
      }
      if(integrations === 75){
        clearInterval(myInterval);
      }else{
        setIntegrations(integrations + 1);
      }
      if(countries === 7){
        clearInterval(myInterval);
      }else{
        setCountries(countries + 1);
      }
    }, 200);
    return () => {
      clearInterval(myInterval);
    };
  })

  const productData = [
    {
      sNo: 2,
      altText: 'assets',
      src: assets.default,
      text: `FOURA'AI Assets is a user-friendly asset tracking software to track and manage fixed IT assets in
        your organization. Make tracking easy and avoid unexpected penalties from audits forever.`,
      color: '#92c753',
      path:'assets'
    },
    {
      sNo: 1,
      altText: 'crm',
      src: crm.default,
      text: `FOURA'AI CRM is a cloud-based CRM, which helps to strengthen business realtionships with your
      customers, increase customer Satisfaction, and gain customer loyalty.`,
      color: '#eaab1c',
      path:'crm'
    },
    {
      sNo: 3,
      altText: 'incidents',
      src: incidents.default,
      text: `FOURA'AI Incidents is a ticketing software, which help to effortlessly track the complete lifcycle of
        an issue from reporting to  closure. Prioritize, track & resolve issues quickly.`,
      color: '#27aae0',
      path:'incidents'
    },
    {
      sNo: 4,
      altText: 'payroll',
      src: payroll.default,
      text: `FOURA'AI Payroll is a Global Payroll software with an intuitive and user-friendly interface. It is
        statutory compliant and easily configurable according to any country's taxation and compliance.`,
      color: '#0eab9e',
      path: 'payroll'
    }
  ]

  const routeToPage = (page) => history.push(`/${page}`);

  return(
    <Fragment>
      <div className="alignCenter header">FOURA'AI</div>
      <div className="alignCenter subheader">
        Cloud suite of User-Friendly Applications
      </div>
      <Container>
        <Row>
        {productData.map(data => <ApplicationInfo data={data} routeToPage={()=>routeToPage(data?.path)}/>)}
        </Row>
        <Row className="mt-5">
          <Col xs={12}>
            <Card className="whyfouraai">
                <CardBody>
                    <h2 className="alignCenter">WHY FOURA’AI</h2>
                    <h4 className="alignCenter">We Value our customers.</h4>
                    <h6 className="alignCenter">We are committed to delivering you the best suite of cloud products in the industry with advanced features at an affordable cost.</h6>
                </CardBody>
              </Card>
          </Col>
        </Row>
        <Row className="mt-5 fouraaiap">
          <Col>
            <h2 className="alignCenter">Why you need to choose FOURA’AI Applications?</h2>
          </Col>
        </Row>
        <Row className="fouraaiapps alignCenter mt-5">
          <Col xs={4}>
            <img src={easy.default} alt="" />
            <h5>Easy to use</h5>
            <p>User-friendly Applications with common terminologies and attractive user interface. 
              The analytics like reporting interface is a hit with users with no separate investment for BI.</p>
          </Col>
          <Col xs={4}>
            <img src={lowcost.default} alt="" />
            <h5>Cost-effective</h5>
            <p>Cloud applications with advanced features, 
              Integrations & unique customizations are being offered at a low cost.</p>
          </Col>
          <Col xs={4}>
            <img src={product.default} alt="" />
            <h5>Product training</h5>
            <p>Complete product training will be given for the entire team for 3 days. 
              Get trained by an expert team and start using applications like a pro.</p>
          </Col>
        </Row>
        <Row className="fouraaiapps alignCenter mt-5">
          <Col xs={4}>
            <img src={whitelabel.default} alt="" />
            <h5>Branding</h5>
            <p>Application can be labeled in your Brand name, with brand color and Logo.</p>
          </Col>
          <Col xs={4}>
            <img src={mobile.default} alt="" />
            <h5>Mobile Application</h5>
            <p>Create a Mobile Application for IOS & Android, so the application is accessible even on the run.</p>
          </Col>
          <Col xs={4}>
            <img src={smooth.default} alt="" />
            <h5>Smooth Integrations</h5>
            <p>Easily integrate with your existing ERP systems.</p>
          </Col>
        </Row>
        <Row className="fouraaiapps alignCenter mt-5">
          <Col xs={4}>
            <img src={advanced.default} alt="" />
            <h5>Advanced features</h5>
            <p>FOURA’AI suite of Products are 
              cloud applications that are built with advanced features like
               high-level automation, Analytics, Tracking, Reporting, forecasting, etc.</p>
          </Col>
          <Col xs={4}>
            <img src={automatic.default} alt="" />
            <h5>Automatic software upgrade</h5>
            <p>Product features will be updated every year to offer the best products to customers. 
              Newly added features will be automatically updated in your module.</p>
          </Col>
          <Col xs={4}>
            <img src={highly.default} alt="" />
            <h5>Highly customizable</h5>
            <p>The product comes loaded with features and you will require no additional 
              enhancements however required 
              features, or any additional functionalities can be built in no-time.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={3} className="alignCenter">
            <span className="counter">{products}+</span>
            <div style={{fontWeight:'bold'}}>Products</div>
          </Col>
          <Col xs={12} sm={12} md={3} className="alignCenter">
            <span className="counter">{countries}+</span>
            <div style={{fontWeight:'bold'}}>Countries</div>
          </Col>
          <Col xs={12} sm={12} md={3} className="alignCenter">
            <span className="counter">{features}+</span>
            <div style={{fontWeight:'bold'}}>Features</div>
          </Col>
          <Col xs={12} sm={12} md={3} className="alignCenter">
            <span className="counter">{integrations}+</span>
            <div style={{fontWeight:'bold'}}>Integrations</div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12}>
            <Card className="takelook">
                <CardBody>
                    <h2 className="alignCenter">TAKE A LOOK AT​</h2>
                    <h4 className="alignCenter">How our support works?</h4>
                    <h2 className="alignCenter"><Button onClick={()=>routeToPage('support')} className="explorebtn">Explore</Button></h2>
                </CardBody>
              </Card>
          </Col>
        </Row>
        <Row className="mt-5 fouraaiap">
          <Col>
            <h2 className="alignCenter">GET TO KNOW OUR PRODUCTS</h2>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} sm={6} md={6} lg={3}>
            <div className="ourproduct">
              <img src={assets.default} alt="" />
            </div>
            <div className="mt-5 alignCenter mb-2">
              <Button className="assetsbtn" onClick={()=>{routeToPage('BookDemo')}}>BOOK A DEMO</Button>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <div className="ourproduct">
              <img src={crm.default} alt="" />
            </div>
            <div className="mt-5 alignCenter mb-2">
              <Button className="crmbtn" onClick={()=>{routeToPage('BookDemo')}}>BOOK A DEMO</Button>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <div className="ourproduct">
              <img src={incidents.default} alt="" />
            </div>
            <div className="mt-5 alignCenter mb-2">
              <Button className="incidentsbtn" onClick={()=>{routeToPage('BookDemo')}}>BOOK A DEMO</Button>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <div className="ourproduct">
              <img src={payroll.default} alt="" />
            </div>
            <div className="mt-5 alignCenter mb-2">
              <Button className="payrollbtn" onClick={()=>{routeToPage('BookDemo')}}>BOOK A DEMO</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ApplicationWrapper