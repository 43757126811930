import React, { Fragment, useEffect, useState } from "react";
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import momenttz from "moment-timezone";
import { Container, Label } from "reactstrap";
//store
import { useDemoDispatch } from '../../context/DemoContext';
import * as actionType from '../../type';

const AppointmentDate = (props) => {
  const dispatch = useDemoDispatch();
  const [date, setDate] = useState(moment());
  const [selTime, setSelTime] = useState('');

  useEffect(()=>{
    let selectedDate = moment(date).format('Do, MMMM YYYY')
    let day = moment(date).format('dddd');
    let appointmentDate = selectedDate+' - '+day
    dispatch({type: actionType?.SET_DATE, payload:appointmentDate});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[date]);

  const  DatePicker = ({ date, onChange }) => {
    const [focused, setFocused] = useState(false);
  
    const keepFocus = ({focused}) => {
      if(focused){
        setFocused(focused)
      }else{
        return 
      }
    }

    const renderCalendarInfo = () => {
  
      const selectAppTime = (data,index) => {
        setSelTime(index);
        dispatch({type: actionType?.SET_TIME, payload:data});
      }

      const determineStyle = (index) => {
        const isItemSelected = selTime === index;
        return isItemSelected ;
      }

      return(
        <Container>
          <span style={{fontWeight: "bold"}}>Available Times :</span>
          <div className="timeContainer mt-2">
          {['10:00','11:00','12:00','13:00','14:00','15:00','16:00', '17:00'].map((data,i) => 
            <div className="time" style={determineStyle(i) ? {backgroundColor:'blue', color:'white'}: null}
            onClick={()=>selectAppTime(data,i)} key={i}>{data}</div>)}
          </div>
        </Container>
      )
    }

    return (
      <Fragment>
      <SingleDatePicker
        numberOfMonths={1}
        onDateChange={date => onChange({ target: { value: date } })}
        // onFocusChange={({ focused }) => setFocused(focused)}
        renderCalendarInfo={renderCalendarInfo} 
        onFocusChange={keepFocus}
        focused={true}
        date={date}
        displayFormat="YYYY-MM-DD"
        isDayBlocked={m => m.day() === 6 || m.day() === 0}
        hideKeyboardShortcutsPanel
        withFullScreenPortal={window.innerWidth < 400}
        block
      />
      </Fragment>
    );
  }
  
  let now = moment().tz(moment.tz.guess()).format('z');
  let NewYork_tz_offset = moment(new Date()).tz(moment.tz.guess(Boolean)).format('Z'); 


  return (
    <Fragment>
      <div className="mb-2">
        <Label>{`Your Timezone: (GMT${NewYork_tz_offset}) ${now}`}</Label>
      </div>
      <DatePicker date={date} onChange={e => setDate(e.target.value)} />
    </Fragment>
  );
};

export default AppointmentDate