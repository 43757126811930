import React, { Fragment, useState, useEffect } from 'react';
import { Container, Spinner } from "reactstrap";
import { Form, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import moment from 'moment';
//api
import { apiConfig } from "../../services/config/apiConfig";
import api from "../../services/config/api";
//store
import { useCommentDispatch, useCommentState } from '../../context/CommentContext';
import * as actionType from '../../type';

const CommentSection = (props) => {
  const [spinner, setSpinner] = useState(false);
  const dispatch = useCommentDispatch();
  const { commentData } = useCommentState();
  const [formValues, setFormValues] = useState({
    name:null, email:null, mobileNo:null, comment:null
  })

  useEffect(()=>{
    getCommentsForPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const getCommentsForPost = async () => {
    setSpinner(true);
    let blogId={
      "blog": props?.blogId
    }
    try {
      let result = await api.post(apiConfig.getComment, blogId, {handlerEnabled: false});
      console.log('result', result)
      dispatch({type: actionType?.GET_COMMENT, payload: result?.data});
      setSpinner(false);
    } catch (error) {
      console.log('error', error)
      setSpinner(false);
    }
  }

  const postComment = async () => {
    setSpinner(true);
    let commentData = {
      "blog_id": props?.blogId,
      "full_name": formValues.name,
      "email": formValues.email,
      "mobile_no": formValues.mobileNo,
      "comment": formValues.comment
    };
    let commentDetail = {
      comment: formValues.comment,
      created_at: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],
      email: formValues.email,
      full_name: formValues.name,
      mobile_no: formValues.mobileNo
    }
    try {
      await api.post(apiConfig.addComment, commentData, {handlerEnabled: false});
      dispatch({type: actionType?.ADD_COMMENT, payload: commentDetail});
      setSpinner(false);
    } catch (error) {
      console.log('error', error)
      setSpinner(false);
    }
  }

  const handleFormFields = (e, field) => {
    let value= e.target.value;
    setFormValues(prevState => ({ ...prevState, [field]: value }));
  }

  const comments = () => {
    return(
      <Fragment>
        {
         commentData.length && commentData.map(data => {
           return(
             <Fragment>
               <div className="comsection">
                <div className="replyHdr">
                  <span className="user">{data.full_name}</span>
                  <span className="date">{moment(data?.created_at).format('MMM DD, YYYY hh:mm:ss a')}</span>
                  {/* <div className="reply">Reply</div> */}
                </div>
                <div className="comment">
                  {data?.comment}
                </div>
              </div>
             </Fragment>
           )
         }) 
        }
      </Fragment>
    )
  }

  return(
    <Fragment>
      {spinner ? <Spinner />: null}
      <Container>
        <div className="blogheader mb-4">{`${commentData.length} Comments`}</div>
        {commentData.length ? comments(): null}
        <div className="blogheader mt-4 mb-4">Leave a Reply</div>
        <div className="blogSubheader">Your email address will not be published, Required fields are marked as<Marked /></div>
        <main className="mt-2 mb-1">
          <Form>
            <FormGroup>
              <Label for="comment">Comment<Marked /></Label>
              <Input type="textarea" name="comment" onChange={(e)=>handleFormFields(e,'comment')} value={formValues.comment}/>
            </FormGroup>
            <Row>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label for="name">Name<Marked /></Label>
                  <Input type="text" name="name" onChange={(e)=>handleFormFields(e,'name')} value={formValues.name} />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label for="email">Email<Marked /></Label>
                  <Input type="email" name="email" onChange={(e)=>handleFormFields(e,'email')} value={formValues.email} />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label for="mobileNo">Mobile No</Label>
                  <Input type="text" name="mobileNo" onChange={(e)=>handleFormFields(e,'mobileNo')} value={formValues.mobileNo} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Button className="cmtBtn" onClick={postComment} disabled={formValues?.comment && formValues?.name && formValues?.email ? false: true}>
                  Post Comment
                </Button>
              </Col>
            </Row>
          </Form>
        </main>
      </Container>
    </Fragment>
  )
}

const Marked = () => {
  return(
    <span className="ml-2 red">*</span>
  )
}

export default CommentSection