import React, { Component, Fragment } from 'react';
import incidentbanner from '../../images/incidents-images/Incidents - Inner page.png'
import incidentpage from '../../images/incidents-images/INCIDENTS_Final.jpg'
import incident1 from '../../images/incidents-images/Incidents-1.png'
import incident2 from '../../images/incidents-images/Incidents-2.png'
import incident3 from '../../images/incidents-images/Incidents-3.png'
import incident4 from '../../images/incidents-images/Incidents-4.png'
import incident5 from '../../images/incidents-images/Incidents-5.png'
import incident6 from '../../images/incidents-images/Incidents-6.png'
import { Container, Button ,Row, Col,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Incidentscarousal from './incidentscarosual';
import Pricing from '../../pages/pricingForm';
import './incidents.css';

class Incidents extends Component {
    state ={
        model:false
    }
    componentDidMount(){
        window.scrollTo(0, 0);
      }
    pricing = ()=>{
        console.log("price invoked");
        this.setState({modal:true})
    }
    cancelmodal=()=>{
        console.log("cancel mo");
        this.setState({modal:false})

    }
    routeToPage = (page) => this.props.history.push(`/${page}`);
    render() {
        return (
           <Fragment>
                    <Row>
                        <Col><img src={incidentbanner} alt="" style={{width:"100%"}}/></Col>
                        <div className="incident_caption">
                            <span>Increase efficiency of your helpdesk team, 
                                strengthen brand Image and Reputation using FOURA’AI Incidents</span>
                            <div>
                                <Button className="signupBtn" style={{backgroundColor:'#47799E', border:`1px solid #47799E`}}>
                                Sign up for Free
                                </Button>
                                <Button className="demoBtn" style={{color:'#47799E'}}>Book a demo</Button>
                            </div>
                        </div>
                    </Row>
               <Container>
                    <Row className="mt-10">
                        <Col xs={6} md={6}>
                            <h6>FOURA’AI Incidents is a ticketing software, which helps to effortlessly track the complete lifecycle of an issue from reporting to closure. 
                                Prioritize, track & resolve customer issues quickly to grow your business by providing the best customer support. It matches the ITIL standard.</h6>
                            <b>Business Benefits using FOURA’AI Incidents.</b>
                                <li>Deliver best customer service.</li>
                                <li>Boost team productivity by ticket automation.</li>
                                <li>Provide Quick Resolutions using priority-based SLA management.</li>
                                <li>Easily track your problem resolution timings.</li>
                                <li>Respond instantly using automation.</li>
                        </Col>
                        <Col xs={6} md={6}>
                            <img style={{width:'100%'}} src={incidentpage} alt="" />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={3}></Col>
                        <Col md={6}><h3>FOURA’AI Incidents- Key Features</h3></Col>
                        <Col md={3}></Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={incident1} alt="" />
                        </Col>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset incid"> 
                                    <i class="fa fa-dashcube" /><span className="incidenttitle">Automatic ticket creation</span>
                                    <h6>Create tickets instantly by sending a mail that will automatically raise as a ticket.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-trello" /><span className="incidenttitle">SLA Management</span>
                                    <h6>Conveniently define your own SLA's and escalations as per business requirements.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-random" /><span className="incidenttitle">Incident Prioritization</span>
                                    <h6>Prioritize your issues quickly as L1, L2, L3 based on the level of urgency and resolve accordingly.</h6>
                                </div>
                        </Col> 
                    </Row>

                    <Row>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset incid"> 
                                    <i class="fa fa-bandcamp" /><span className="incidenttitle">Dashboard</span>
                                    <h6>The dashboard provides clear information regarding the number of tickets currently Open, Closed, In-progress, awaiting confirmation, or in hold using bar graphs.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-weibo" /><span className="incidenttitle">Report generation</span>
                                    <h6>Generate reports quickly using filters such as Incident code, project name, customer, priority, assigned to, raised date, etc. 
                                        Generated reports can be readily downloaded and shared quickly via email.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-archive" /><span className="incidenttitle">Alert Notifications</span>
                                    <h6>Get automatic notifications during ticket creation, update, and closure.</h6>
                                </div>
                        </Col> 
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={incident2} alt="" />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={incident3} alt="" />
                        </Col>
                        <Col xs={6} md={6} className="mt-10">
                                <div className="fieldsset incid"> 
                                    <i class="fa fa-id-badge" /><span className="incidenttitle">Customer Service History</span>
                                    <h6>Access master information of the tickets starting from raised date, customer name, consultant name to the closure of the ticket.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-bell-o" /><span className="incidenttitle">Comprehensive Knowledge Database</span>
                                    <h6>Resolved issues will be stored in the knowledge database where you can easily search for solutions for the current problem from resolved tickets.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-qrcode" /><span className="incidenttitle">Response Editor</span>
                                    <h6>A predefined response will be automatically sent as a reply to the mail.</h6>
                                </div>
                        </Col> 
                    </Row>

                    <Row>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset incid"> 
                                    <i class="fa fa-building-o" /><span className="incidenttitle">Daily Planner</span>
                                    <h6>Executives can plan their activities for the day and mention tickets they are going to work on.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-map-marker" /><span className="incidenttitle">Consultant Tracker</span>
                                    <h6>Effortlessly track the performance of consultants working on tickets based on ticket resolution time.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-clock-o" /><span className="incidenttitle">Time Tracker</span>
                                    <h6>Track your tickets efficiently while the clock is running.</h6>
                                </div>
                        </Col> 
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={incident4} alt="" />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={incident5} alt="" />
                        </Col>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset incid"> 
                                    <i class="fa fa-calendar-o" /><span className="incidenttitle">Calendar Mapping</span>
                                    <h6>Map your calendar with the client's calendar for a better workstyle.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-mobile" /><span className="incidenttitle">Super Admin account</span>
                                    <h6>Centralized Repository to log and track issues based on the complete usage of the project.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-pencil-square-o" /><span className="incidenttitle">Automatic software upgrade</span>
                                    <h6>Product features will be updated every year to offer the best products to customers. 
                                        Newly added features will be automatically updated in your module.</h6>
                                </div>
                        </Col> 
                    </Row>

                    <Row>
                        <Col xs={6} md={6} className="mt-5">
                                <div className="fieldsset incid"> 
                                    <i class="fa fa-building-o" /><span className="incidenttitle">API Integration</span>
                                    <h6>Seamlessly integrate FOURA’AI Incidents with current software. Integrations are possible with any software as per your requirement.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-mobile" /><span className="incidenttitle">Mobile Application</span>
                                    <h6>Mobile Application for IOS & Android..</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-user-md" /><span className="incidenttitle">Customization</span>
                                    <h6>Highly customizable as per your business requirements, functionalities, Branding, & integrations.</h6>
                                </div>
                                <div className="fieldsset incid">
                                    <i class="fa fa-cubes" /><span className="incidenttitle">Branding</span>
                                    <h6>Label your CRM application in Brand Name, brand color, and Brand logo.</h6>
                                </div>
                        </Col> 
                        <Col xs={6} md={6}>
                                <img style={{width:'100%'}} src={incident6} alt="" />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4}></Col>
                        <Col md={4}><h3>Get to know your pricing</h3></Col>
                        <Col md={4}></Col>
                    </Row>
                    <Row>
                        <Col md={5}></Col>
                        <Col className="mt-5" md={2}><Button onClick={this.pricing} className="incidentpricing">Pricing</Button></Col>
                        <Col md={5}></Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={2}></Col>
                        <Col md={8}><Incidentscarousal /></Col>
                        <Col md={2}></Col>
                    </Row>
                    <Row className="mt-5 mb-5">
                        <Col md={4}></Col>
                        <Col md={2}><Button onClick={()=>this.routeToPage('sign-up-incidents')} className="incidentgetstarted">GET STARTED</Button></Col>
                        <Col md={2}><Button onClick={() => this.routeToPage("BookDemo")} className="incidentbookademo">BOOK A DEMO</Button></Col>
                        <Col md={4}></Col>
                    </Row>
                </Container>
                <Modal isOpen={this.state.modal} toggle={this.cancelmodal} >
        <ModalHeader toggle={this.cancelmodal}>Pricing</ModalHeader>
        <ModalBody>
           <Pricing modal={this.cancelmodal} product="incidents"/>
           
        </ModalBody>
    
      </Modal>
           </Fragment>
        )
    }
}
export default Incidents
