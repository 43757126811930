import React, { createContext, useContext, useReducer } from 'react';
import demoReducer from '../reducer/DemoReducer';

const initialState = {location:"", date:"", time:"", bookedDemo: false}

const DemoStateContext = createContext(initialState);
const DemoDispatchContext = createContext(undefined);

const DemoProvider = ({children}) => {
  const [state, dispatch] = useReducer(demoReducer, initialState)
  return(
    <DemoStateContext.Provider value={state}>
      <DemoDispatchContext.Provider value={dispatch}>
        {children}
      </DemoDispatchContext.Provider>
    </DemoStateContext.Provider>
  )
}

function useDemoState() {
  const context = useContext(DemoStateContext);
  if(context === undefined){
    throw new Error('useDemoState must be used within a DemoProvider')
  }
  return context
}

function useDemoDispatch() {
  const context = useContext(DemoDispatchContext);
  if(context === undefined){
    throw new Error('useDemoDispatch must be used within a DemoProvider')
  }
  return context
}

export { DemoProvider, useDemoState, useDemoDispatch }
