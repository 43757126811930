import * as actionType from '../type';

const commentReducer = (state, action) => {
  switch(action?.type){
    case actionType?.GET_COMMENT:{
      return{
        ...state,
        commentData: [...state.commentData, ...action?.payload]
      }
    }
    case actionType?.ADD_COMMENT:{
      return{
        ...state,
        commentData: [...state.commentData, {...action?.payload}]
      }
    }
    default:
      return state
  }
}

export default commentReducer