import React, { Component, Fragment } from "react";
import { Container, Button, Row, Col } from "reactstrap";
import bannerimage from "../../images/partners/Partner---Main-page---final.png";
import one from "../../images/partners/one.png";
import two from "../../images/partners/two.png";
import three from "../../images/partners/three.png";
import four from "../../images/partners/four.png";
import gif from "../../images/partners/Grey_arrows.gif";
import Form from "./form";
import "../../css/partners.css";

class Partners extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Fragment>
        <Row>
          <img src={bannerimage} style={{ width: "100%" }} alt="" />
          <div className="partner_caption">
            <span>
              You will be our extended support to generate leads for FOURA’AI
              suite of cloud Applications.
            </span>
          </div>
        </Row>
        <Container>
          <Row className="mt-10" xs={12}>
            <Col className="alignCenter">
              <h2 className="partner">FOURA’AI Partner Program</h2>
            </Col>
          </Row>
          <Row className="mt-5" xs={12}>
            <Col className="alignCenter">
              <h5>Get associated with us</h5>
            </Col>
          </Row>
          <Row className="mt-5" xs={12}>
            <Col className="alignCenter">
              <h5>Benefits</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <div className="mt-5 alignCenter">
                <i class="fa fa-list" />
              </div>
              <div className="mt-5 alignCenter">
                <h4>Get listed in our website</h4>
              </div>
            </Col>
            <Col xs={4}>
              <div className="mt-5 alignCenter">
                <i class="fa fa-connectdevelop" />
              </div>
              <div className="mt-5 alignCenter">
                <h4>FOURA’AI Partner Logo</h4>
              </div>
            </Col>
            <Col xs={4}>
              <div className="mt-5 alignCenter">
                <i class="fa fa-whatsapp" />
              </div>
              <div className="mt-5 alignCenter">
                <h4>Marketing support</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <div className="mt-5 alignCenter">
                <i class="fa fa-user-circle" />
              </div>
              <div className="mt-5 alignCenter">
                <h4>Product Training</h4>
              </div>
            </Col>
            <Col xs={4}>
              <div className="mt-5 alignCenter">
                <i class="fa fa-wpforms" />
              </div>
              <div className="mt-5 alignCenter">
                <h4>Content for sales pitch</h4>
              </div>
            </Col>
            <Col xs={4}>
              <div className="mt-5 alignCenter">
                <i class="fa fa-street-view" />
              </div>
              <div className="mt-5 alignCenter">
                <h4>Assignment of channel partner</h4>
              </div>
            </Col>
          </Row>
          <Row className="mt-5" xs={12}>
            <Col className="alignCenter">
              <h2 className="partner">How it works?</h2>
            </Col>
          </Row>
          <Row className="mt-5" xs={12}>
            <Col xs={3} md={2}>
              <div className="mt-5 alignCenter partnerimg">
                <img src={one} alt="" />
              </div>
              <div className="mt-5 alignCenter">
                <h5 className="subtitle">Generate a lead</h5>
              </div>
            </Col>
            <Col xs={3} md={1} className="arrowgreater">
              <img src={gif} alt="" />
            </Col>

            <Col xs={3} md={2}>
              <div className="mt-5 alignCenter partnerimg">
                <img src={two} alt="" />
              </div>
              <div className="mt-5 alignCenter">
              <h5 className="subtitle">Schedule a demo with your lead</h5>
              </div>
            </Col>
            <Col xs={3} md={1} className="arrowgreater">
                <img src={gif} alt="" />
            </Col>
            <Col xs={3} md={2}>
              <div className="mt-5 alignCenter partnerimg">
                <img src={three} alt="" />
              </div>
              <div className="mt-5 alignCenter">
              <h5 className="subtitle">Introduce your lead to our sales team</h5>
              </div>
            </Col>
            <Col xs={3} md={1} className="arrowgreater">
                <img src={gif} alt="" />
            </Col>
            <Col xs={3} md={2}>
              <div className="mt-5 alignCenter partnerimg">
                <img src={four} alt="" />
              </div>
              <div className="mt-5 alignCenter">
              <h5 className="subtitle">Get paid for each Lead</h5>
              </div>
            </Col>
          </Row>
          <Row className="mt-5" xs={12}>
            <Col className="alignCenter">
              <h3>Kindly fill the form below</h3>
            </Col>
          </Row>
          <Row className="mt-5" xs={12}>
            <Form />
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default Partners;
