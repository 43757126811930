import React, { createContext, useContext, useReducer } from 'react';
import commentReducer from '../reducer/CommentReducer';

const initialState = {commentData:[]}

const CommentStateContext = createContext(initialState);
const CommentDispatchContext = createContext(undefined);

const CommentProvider = ({children}) => {
  const [state, dispatch] = useReducer(commentReducer, initialState)
  return(
    <CommentStateContext.Provider value={state}>
      <CommentDispatchContext.Provider value={dispatch}>
        {children}
      </CommentDispatchContext.Provider>
    </CommentStateContext.Provider>
  )
}

function useCommentState() {
  const context = useContext(CommentStateContext);
  if(context === undefined){
    throw new Error('useCommentState must be used within a CommentProvider')
  }
  return context
}

function useCommentDispatch() {
  const context = useContext(CommentDispatchContext);
  if(context === undefined){
    throw new Error('useCommentDispatch must be used within a CommentProvider')
  }
  return context
}

export { CommentProvider, useCommentState, useCommentDispatch }
