import React, { Fragment, useState } from "react";
import { Container, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
//css
import "../../css/partners.css";
//api
import { apiConfig } from "../../services/config/apiConfig";
import api from "../../services/config/api";

const PartnerForm = () => {
  const [spinner, setSpinner] = useState(false);
  const [formvalidation, setformvalidation] = useState(false);
  const [successFlag, setSuccessFlag] = useState(false);
  const [formValues, setFormValues] = useState({
    email: null, name: null, phoneNumber: null, companyName: null, linkedinUrl:null, designation:null
  });

  const handleFormFields = (e, field) => {
    let value = e.target.value;
    setFormValues((prevState) => ({ ...prevState, [field]: value }));
  };

  const submitForPartner = async () => {
    if(formValues.name&&formValues.email&&formValues.companyName&&formValues.phoneNumber){
      setformvalidation(false)
      let partnerInfo = {
        name: formValues?.name,
        company_name : formValues?.companyName,
        email : formValues?.email,
        phone : formValues?.phoneNumber,
        designation : formValues?.designation,
        linked_in_url : formValues?.linkedinUrl
      }
      try {
        await api.post(apiConfig.partner, partnerInfo, {handlerEnabled: false});
        setSpinner(false);
        setSuccessFlag(true);
      } catch (error) {
        console.log('error', error)
        setSpinner(false);
      }
    }else{
      setformvalidation(true);
    }
  }

  return (
    <Fragment>
      {spinner ? <Spinner />: null}
      <Container>
        <Form>
          <FormGroup>
            <Label>Enter Name <Marked /></Label>
            <Input type="text" name="name" onChange={(e) => handleFormFields(e, "name")}
              value={formValues.name}/>
          </FormGroup>
          <FormGroup>
            <Label>Email <Marked /></Label>
            <Input type="email" name="email" onChange={(e) => handleFormFields(e, "email")}
              value={formValues.email}/>
          </FormGroup>
          <FormGroup>
            <Label>Company Name <Marked /></Label>
            <Input
              type="text"
              name="companyname"
              onChange={(e) => handleFormFields(e, "companyName")}
              value={formValues.companyName}
            />
          </FormGroup>
          <FormGroup>
            <Label>Phone Number <Marked /></Label>
            <Input
              type="text"
              name="phonenumber"
              onChange={(e) => handleFormFields(e, "phoneNumber")}
              value={formValues.phoneNumber}
            />
          </FormGroup>
          <FormGroup>
            <Label>Linkedin URL</Label>
            <Input
              type="text"
              name="linkedinurl"
              onChange={(e) => handleFormFields(e, "linkedinUrl")}
              value={formValues.linkedinUrl}
            />
          </FormGroup>
          <FormGroup>
            <Label>Designation</Label>
            <Input
              type="text"
              name="designation"
              onChange={(e) => handleFormFields(e, "designation")}
              value={formValues.designation}
            />
          </FormGroup>
          {formvalidation?<FormGroup  className="texterror"  >
            <div >Please fill the mandatory fields</div>
          </FormGroup>:null}
          {successFlag ?<span style={{color:'blue'}} className="mb-2" >Thanks for contacting us. We will get in touch with you shortly!</span>:null}
          <Input className="sendbtn" type="button" value="Join Now"  onClick={submitForPartner} />
        </Form>
      </Container>
    </Fragment>
  );
};


const Marked = () => {
  return <span className="ml-2 red">*</span>;
};

export default PartnerForm;
