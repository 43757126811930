export const apiConfig = {
  baseURL: "https://events.4iapps.com/4iapps/blog/api/blog/",
  getBlog: "get",
  addComment: "comment/add",
  getComment: "comment/get",
  replyComment: "comment/reply/",
  bookDemo: "form/book_demo/add",
  contact: "form/contact/add",
  support: "form/support/add",
  partner: "form/partners/add",
  signUp: "form/signup/add",
  pricing: "form/pricing/add"
}