import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardImg, CardTitle, CardSubtitle, CardText, CardBody, CardLink } from 'reactstrap';
import moment from 'moment';
import './blog.css';

const BlogOverview = ({data, readFurther}) => {

  return(
    <Fragment>
      <Card className="blogCard">
        <CardImg top width="100%" height="300px" src={`data:image/jpeg;base64,${data?.image?.child}`} alt={data?.title}/>
        <CardBody>
          <CardTitle tag="h5">{data?.title}</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">{moment(data?.created_at).format('MMM DD, YYYY')}</CardSubtitle>
          <CardText className="">{`${data?.description.substring(0, 200)} ...`}</CardText>
          <CardLink className="pointer" onClick={()=>readFurther(data)}>Read More</CardLink>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default BlogOverview