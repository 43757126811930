import React, { Fragment, useState } from "react";
import { Container, Button, Form, FormGroup, Label, Input, Spinner, Badge } from "reactstrap";
import "./bookdemo.css";
//api
import { apiConfig } from "../../services/config/apiConfig";
import api from "../../services/config/api";
//store
import { useDemoState, useDemoDispatch } from "../../context/DemoContext";
import * as actionType from "../../type";

function PersonalInfo() {
  const [spinner, setSpinner] = useState(false);
  const [formvalidation, setformvalidation] = useState(false);

  const [formValues, setFormValues] = useState({
    email: null, name: null, phoneNumber: null, description: null,
  });
  const { location, time, date } = useDemoState();
  const dispatch = useDemoDispatch();
  
  const handleFormFields = (e, field) => {
    let value = e.target.value;
    setFormValues((prevState) => ({ ...prevState, [field]: value }));
  };

  const onSubmitBookDemo = async () => {
    if(formValues.name&&formValues.email&&formValues.description&&formValues.phoneNumber){
      setSpinner(true);
    let demoInfo = {
      name: formValues.name,
      email: formValues.email,
      phone: formValues.phoneNumber,
      location: location,
      date:  date,
      time: time,
      description: formValues.description,
    };
    try {
      await api.post(apiConfig.bookDemo, demoInfo, {handlerEnabled: false});
      dispatch({type: actionType?.BOOKED_DEMO, payload: true});
      setSpinner(false);
    } catch (error) {
      console.log('error', error)
      setSpinner(false);
    }
    }
    else{
      setformvalidation(true)
    }
  };


  const clearLocation = () => dispatch({type: actionType?.SELECTED_LOCATION, payload:''});
  
  return (
    <Fragment>
      {spinner ? <Spinner />: null}
      <Container>
        <div className="mb-2">
          <span className="mr-3">Location: </span>
          <Badge color="dark">
            {location}<i class="fa fa-times-circle ml-2 pointer" aria-hidden="true" onClick={clearLocation}></i>
          </Badge>
        </div>
        <Form className={date && time?null:"formDisable"}>
          <FormGroup> 
            <Label>Email <Marked /></Label>
            <Input
              type="email"
              name="email"
              placeholder="Official Email"
              onChange={(e) => handleFormFields(e, "email")}
              value={formValues.email}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Name
              <Marked />
            </Label>
            <Input
              type="text"
              placeholder="Name"
              name="name"
              onChange={(e) => handleFormFields(e, "name")}
              value={formValues.name}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Phone Number
              <Marked />
            </Label>
            <Input
              type="text"
              placeholder="Phone Number"
              name="phoneNumber"
              onChange={(e) => handleFormFields(e, "phoneNumber")}
              value={formValues.phoneNumber}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Description
              <Marked />
            </Label>
            <Input
              type="textarea"
              placeholder="Description"
              name="description"
              onChange={(e) => handleFormFields(e, "description")}
              value={formValues.description}
            />
          </FormGroup>
          {formvalidation?<FormGroup  className="texterror"  >
        <div >Please fill the mandatory fields</div>

      </FormGroup>:null}
          
          <FormGroup className="btnlayer">
            <Button>Cancel</Button>
            <Button
              className="submitbtn"
              onClick={onSubmitBookDemo}
              // disabled={
              //   formValues?.email &&
              //   formValues?.phoneNumber &&
              //   formValues?.description
              //     ? false
              //     : true
              // }
            >
              Submit
            </Button>
          </FormGroup>
        </Form>
      </Container>
    </Fragment>
  );
}

const Marked = () => {
  return <span className="ml-2 red">*</span>;
};

export default PersonalInfo;
