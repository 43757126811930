import React, { Component, Fragment } from 'react';
import { Container, Button ,Row, Col } from 'reactstrap';
import logo from '../../images/signup/Payroll-1.png';
import Signup from './signup';
import '../../css/signup.css';

class SignupPayroll extends Component {
    
    render() {
        return (
           <Fragment>
               <Container>
                   <Row>
                       <Col xs={4}></Col>
                       <Col xs={4}><img src={logo} alt="" /></Col>
                       <Col xs={4}></Col>
                   </Row>
                   <Row className="mt-5 mb-5">
                       <Col xs={5}></Col>
                       <Col xs={4}><h5>Free Trial For 15 Days</h5></Col>
                       <Col xs={3}></Col>
                   </Row>
                    <Signup product="payroll"/>
                </Container>
           </Fragment>
        )
    }
}
export default SignupPayroll
