import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
//components
import BlogContent from "./BlogContent";
import CommentSection from "./CommentSection";

const BlogContentLayout = (props) => {

  return (
    <Fragment>
      <Container>
        <Row>
          <Col xs={12}>
            <BlogContent blogId={props.blogId}/>
          </Col>
          <Col xs={12}>
            <Container>
              <div className="hrLine"></div>
            </Container>
          </Col>
          <Col xs={12}>
            <CommentSection blogId={props.blogId}/>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BlogContentLayout;
