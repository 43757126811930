import React, { Fragment, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Container, Button, Col, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
//css
import "../../css/signup.css";
//api
import { apiConfig } from "../../services/config/apiConfig";
import api from "../../services/config/api";

const SignUp = () => {
  const location = useLocation();
  const [spinner, setSpinner] = useState(false);
  const [formvalidation, setformvalidation] = useState(false);
  const [successFlag, setSuccessFlag] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: null, lastName: null, email: null, phoneNumber: null, noOfUser: null, companyName:null
  });

  const signUp = async () => {
    if(formValues.firstName&&formValues.email&&formValues.lastName&&formValues.phoneNumber&&
      formValues.companyName&&formValues?.noOfUser){
        setformvalidation(false);
      let signUpInfo = {
        first_name : formValues?.firstName,
        last_name : formValues?.lastName,
        email : formValues?.email,
        company_name: formValues?.companyName,
        no_of_users: parseInt(formValues?.noOfUser),
        phone: formValues?.phoneNumber,
        mode: signUpMode()
      }
      try {
        await api.post(apiConfig.signUp, signUpInfo, {handlerEnabled: false});
        setSpinner(false);
        setSuccessFlag(true);
      } catch (error) {
        console.log('error', error)
        setSpinner(false);
      }
    }else{
      setformvalidation(true);
    }
  };

  const signUpMode = () => {
    if(location?.pathname.includes("asset")){
      return "Asset"
    }else if(location?.pathname.includes("crm")){
      return "Crm"
    }else if(location?.pathname.includes("incidents")){
      return "Incidents"
    }else{
      return "Payroll"
    }
  }

  const handleFormFields = (e, field) => {
    let value = e.target.value;
    setFormValues((prevState) => ({ ...prevState, [field]: value }));
  };

  return (
    <Fragment>
      {spinner ? <Spinner />: null}
      {successFlag ?<span style={{color:'blue'}} className="mb-2" >Thanks for contacting us. We will get in touch with you shortly!</span>:null}
      <Container>
        <Form>
          <FormGroup row>
            <Col sm={6}>
              <Label>First Name <Marked /></Label>
              <Input
                type="text"
                name="firstname"
                onChange={(e) => handleFormFields(e, "firstName")}
                value={formValues.firstName}
              />
            </Col>
            <Col sm={6}>
              <Label>Last Name <Marked /></Label>
              <Input
                type="text"
                name="lastname"
                onChange={(e) => handleFormFields(e, "lastName")}
                value={formValues.lastName}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <Label>Email <Marked /></Label>
              <Input
                type="email"
                name="email"
                placeholder="Enter Email"
                onChange={(e) => handleFormFields(e, "email")}
                value={formValues.email}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <Label>No Of Users <Marked /></Label>
              <Input
                type="select"
                name="nousers"
                onChange={(e) => handleFormFields(e, "noOfUser")}
                value={formValues.noOfUser}
              >
                <option>Number of Users</option>
                <option>0 to 10</option>
                <option>10 to 50</option>
                <option>50 to 100</option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={6}>
              <Label>Company Name <Marked /></Label>
              <Input
                type="text"
                name="companyname"
                onChange={(e) => handleFormFields(e, "companyName")}
                value={formValues.companyName}
              />
            </Col>
            <Col sm={6}>
              <Label>Phone Number<Marked /></Label>
              <Input
                type="text"
                name="phonenumber"
                onChange={(e) => handleFormFields(e, "phoneNumber")}
                value={formValues.phoneNumber}
              />
            </Col>
          </FormGroup>
          {formvalidation?<FormGroup  className="texterror"  >
            <div >Please fill the mandatory fields</div>
          </FormGroup>:null}
          <div className="btnAlignCenter">
            <Button style={{ backgroundColor: "#02AB9E" }} type="button" onClick={signUp}>
              Submit
            </Button>
          </div>
        </Form>
      </Container>
    </Fragment>
  );
};

const Marked = () => {
  return <span className="ml-2 red">*</span>;
};

export default SignUp;
