import React, { Fragment } from "react";
import Products from "./Products";
import ApplicationWrapper from "./ApplicationWrapper";

const HomeScreen = () => {
  return(
    <Fragment>
      <Products />
      <ApplicationWrapper/>
    </Fragment>
  );
};

export  default HomeScreen
