import React, { Fragment, useState } from 'react';
import { Carousel, CarouselItem, CarouselIndicators, Button } from 'reactstrap';
import '../css/carousel.css';
import { useHistory } from 'react-router-dom';



const Products = () => {
  let history = useHistory();
  //state
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [carousel1] = useState(require("../images/Asset-Main.png"));
  const [carousel2] = useState(require("../images/CRM-Main.png"));
  const [carousel3] = useState(require("../images/Incidents-Main.png"));
  const [carousel4] = useState(require("../images/Payroll-Main-page-final-2.png"));

  const items = [
    {
      src: carousel1.default,
      altText: 'Slide 1',
      caption: 'Explore the all in one FOURAAI Global Assets solution',
      color: '#92c753',
      path: 'sign-up-assets'
    },
    {
      src: carousel2.default,
      altText: 'Slide 2',
      caption: 'Automate your sales force using FOURAAI CRM Software',
      color: '#eaab1c',
      path: 'sign-up-crm'
    },
    {
      src: carousel3.default,
      altText: 'Slide 3',
      caption: 'Offer excellent customer support using FOURAAI Incidents',
      color: '#27aae0',
      path: 'sign-up-incidents'
    },
    {
      src: carousel4.default,
      altText: 'Slide 4',
      caption: 'Explore the all in one FOURAAI Global payroll solution',
      color: '#0eab9e',
      path: 'sign-up-payroll'
    }
  ];

  const next = () => {
    const nextIndex =1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    const nextIndex = 2;
    setActiveIndex(nextIndex);
  }

  const routeToPage = (page) => history.push(`/${page}`);
  const routeToSignUp = (path) => history.push(`/${path}`);

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}>
        <img src={item.src} alt={item.altText} />
        <div className="container">
        <div className="carousel_caption">
          <span>{item.caption}</span>
          <div>
            <Button className="signupBtn" style={{backgroundColor:item.color, border:`1px solid ${item.color}`}}
              onClick={()=>routeToSignUp(item?.path)}>
              Sign up for Free
            </Button>
            <Button className="demoBtn" onClick={()=>routeToPage('BookDemo')} style={{color:item.color}}>Book a demo</Button>
          </div>
        </div>
        </div>
      </CarouselItem>
    );
  });

   const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }
  return(
    <Fragment>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
      </Carousel>
    </Fragment>
  )
}

export default Products