import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input,Spinner } from 'reactstrap';
import dropmailicon from "../images/Support-images/Drop-in-a-mail-@servicedesk@4iapps.com.png";
import Consultanticon from "../images/Support-images/Consultant-will-start-working-on-your-issues.png";
import queryicon from "../images/Support-images/Your-query-details-will-be-gathered.png";
import starticon from "../images/Support-images/Start-working-on-Application-with-ease.png";
import experticon from "../images/Support-images/Our-expert-team-will-reach-you-soon.png";
import issueicon from "../images/Support-images/Issue-will-be-resolved-based-on-your-priority.png";
import dropmail from "../images/Support-images/Drop-in-a-mail-@servicedesk@4iapps.com.png";
import Dedicatedicon from "../images/Support-images/Dedicated-consultant-will-be-assigned.png";
import lines from "../images/Support-images/lines.png";
import leftlines from "../images/Support-images/lines1.png";
import Supportimg from "../images/Support-images/Support---Main-page---final (1).png";
import Supportbticon from "../images/Support-images/Support---Main-page---final (1).png";

import '../css/support.css';

//api
import { apiConfig } from "../services/config/apiConfig";
import api from "../services/config/api";


class Support extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formValues: {
                name: null,
                email: null,
                subject: null, message: null
            },
            spinner:false,
            message:false,
            validation:false
        }
    }
    componentDidMount(){
        window.scrollTo(0, 0);
      }
    onSubmit = async() => {
        if(this.state.formValues.name && this.state.formValues.email && this.state.formValues.subject){
                this.setState({spinner:true,validation:false});
                let supportInfo = {
                    name: this.state.formValues.name,
                    email: this.state.formValues.email,
                    subject: this.state.formValues.subject,
                    message: this.state.formValues.message,
                  };
                  try {
                    await api.post(apiConfig.support, supportInfo, {handlerEnabled: false});
                    this.setState({message:true});
                    this.setState({spinner:false});
                  } catch (error) {
                    console.log('error', error)
                    this.setState({spinner:false});
                  }
            }else{
                this.setState({validation:true})
            }
    }
    handleFormFields = (e, field) => {
        let formValues = { ...this.state.formValues }
        formValues[field] = e.target.value
        this.setState({ formValues })
    }

    render() {
        return (
            <div>{this.state.spinner ? <Spinner />: null}
                <Row>
                    <Col>
                        <img src={Supportimg} style={{ width: '100%' }} />
                    </Col>
                    <div className="support_caption">
                        <span>Your queries will be addressed Immediately</span>
                    </div>
                </Row>
                <Container>
                    <Row>
                        <Col className="mt-5">
                            <div className="headertext"> How does our support work?</div></Col>
                    </Row>
                    <Row>
                        <Col className="alignCenter" md="4">
                            <img src={dropmail} style={{ width: "70%" }} alt="" />
                        </Col>
                        <Col md="4">
                            <div style={{ marginTop: "115px" }}>  Drop-in-a-mail-@servicedesk@4iapps.com</div>
                        </Col>
                        <Col md="4">
                            <img src={lines} alt="" style={{ width: "200px", marginTop: "90px" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <img src={leftlines} alt="" style={{ width: "200px", marginTop: "90px" }} />
                        </Col>

                        <Col md="4">
                            <div style={{ marginTop: "115px" }}>  Our-expert-team-will-reach-you-soon</div>
                        </Col>
                        <Col className="alignCenter" md="4">
                            <img style={{ width: "70%" }} src={experticon} />
                        </Col>

                    </Row>
                    <Row>
                        <Col className="alignCenter" md="4">
                            <img style={{ width: "70%" }} src={queryicon} />
                        </Col>
                        <Col md="4">
                            <div style={{ marginTop: "115px" }}>  Your-query-details-will-be-gathered</div>
                        </Col>
                        <Col md="4">
                            <img src={lines} alt="" style={{ width: "200px", marginTop: "90px" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <img src={leftlines} alt="" style={{ width: "200px", marginTop: "90px" }} />
                        </Col>

                        <Col md="4">
                            <div style={{ marginTop: "115px" }}>A Dedicated-consultant-will-be-assigned</div>
                        </Col>
                        <Col className="alignCenter" md="4">
                            <img style={{ width: "70%" }} src={Dedicatedicon} />
                        </Col>

                    </Row>
                    <Row>
                        <Col className="alignCenter" md="4">
                            <img style={{ width: "70%" }} src={Consultanticon} alt="" />
                        </Col>
                        <Col md="4">

                            <div style={{ marginTop: "115px" }}>  The consultant will start working on your issues.</div>
                        </Col>
                        <Col md="4">
                            <img src={lines} alt="" style={{ width: "200px", marginTop: "90px" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <img src={leftlines} alt="" style={{ width: "200px", marginTop: "90px" }} />
                        </Col>
                        <Col md="4">
                            <div style={{ marginTop: "115px" }}> Issue-will-be-resolved-based-on-your-priority</div>
                        </Col>
                        <Col className="alignCenter" md="4">
                            <img style={{ width: "70%" }} alt="" src={issueicon} />
                        </Col>

                    </Row>
                    <Row>
                        <Col md="4">
                            <img style={{ width: "70%" }} alt="" src={starticon} />
                        </Col>
                        <Col md="4">
                            <div style={{ marginTop: "115px" }}> Consultant-will-start-working-on-your-issues</div>
                        </Col>
                        <Col md="4"></Col>
                    </Row>
                    <Row className="title">
                        <Col className="alignCenter" md="12"><h3>Contact Us</h3></Col>
                    </Row>
                    
                    <Row className="mt-5">
                        <Col md="12">
                            <Form>
                                <FormGroup>
                                    <Label >Your Name<Marked/></Label>
                                    <Input type="text" placeholder="Name" required name="name" onChange={(e) => this.handleFormFields(e, 'name')} value={this.state.formValues.name} />
                                </FormGroup>
                                <FormGroup>
                                    <Label >Your Email<Marked/></Label>
                                    <Input type="email" placeholder="Official Email" required name="email" onChange={(e) => this.handleFormFields(e, 'email')} value={this.state.formValues.email} />
                                </FormGroup>
                                <FormGroup>
                                    <Label >Subject<Marked/></Label>
                                    <Input type="text" placeholder="Subject" required name="subject" onChange={(e) => this.handleFormFields(e, 'subject')} value={this.state.formValues.subject} />
                                </FormGroup>

                                <FormGroup className="position-relative">
                                    <Label >Your Message</Label>
                                    <Input type="textarea" name="message" required onChange={(e) => this.handleFormFields(e, 'message')} value={this.state.formValues.message} />
                                </FormGroup>
                                {this.state.validation?<FormGroup  className="texterror"  >
                                    <div >Please fill the mandatory fields</div>
                                </FormGroup>:null}
                                {this.state.message ?<span style={{color:'blue'}}>Thanks for contacting us. We will get in touch with you shortly!</span>:null}
                                <FormGroup>

                                    <Input className="sendbtn" type="button" value="Send Now"
                                        onClick={this.onSubmit}  />
                                </FormGroup>
                            </Form></Col></Row>
                </Container>
            </div>
        )
    }
}
const Marked = () => {
    return(
      <span className="ml-2 red">*</span>
    )
  }

export default Support;