import axios from "axios";
import { apiConfig } from "./apiConfig";

import { requestHandler, successHandler } from '../provider/requestHandler'

const api = axios.create({
  baseURL: apiConfig.baseURL,
});

api.interceptors.request.use(
  (request) => requestHandler(request),
);

api.interceptors.response.use(
  (response) => successHandler(response),
  (error) => console.log(error)
);


export default api;