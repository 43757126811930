import React, { Fragment } from "react";
import { Col, Card, CardImg, CardText, CardBody, Button } from 'reactstrap';
const ApplicationInfo = ({data, routeToPage}) => {
  return (
    <Fragment>
      <Col xs={12} sm={6} md={6} lg={3}>
        <Card className="appCard">
          <CardImg top width="100%" src={data?.src} alt={data?.altText}/>
          <CardBody>
            <CardText className="appCardText">{data?.text}</CardText>
            <div className="alignCenter">
              <Button className="learnmore" style={{backgroundColor:data.color, border:`1px solid ${data.color}`}}
                onClick={routeToPage}>
                Learn More
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default ApplicationInfo