import React, { Fragment, useState } from "react";
import { useHistory, Link } from 'react-router-dom';
import { Nav, Navbar, NavbarBrand, NavLink, Dropdown,
  DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import logo from '../images/headerimages/Fouraai-logo.png';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { Row, Col } from 'reactstrap';
//css
import '../css/header.css';
const Header = (props) => {
  const {
    className
  } = props;

  const [crm] = useState(require("../images/home/CRM.png"));
  const [assets] = useState(require("../images/home/Assets.png"));
  const [incidents] = useState(require("../images/home/Incidents.png"));
  const [payroll] = useState(require("../images/home/Payroll.png"));


   let history = useHistory();
  const [prodDrpDwn, setPrdDrpDwn] = useState(false);
  const [modal, setModal] = useState(false);
  const [abtUsDrpDwn, setAbtUsPrdDrpDwn] = useState(false);

  const routeToPage = (page) => history.push(`/${page}`);

  const toggle = () => setModal(!modal);

  return (
    <Fragment>
      <Navbar expand="true" fixed="top" style={{backgroundColor:"white", padding:"1.5em"}}>
        <NavbarBrand><img onClick={()=>history.push('/')} className="logo" src={logo} alt="" /></NavbarBrand>
        <Nav className="menualign">
          <Dropdown nav inNavbar isOpen={prodDrpDwn} onMouseEnter={()=>setPrdDrpDwn(true)} 
            onMouseLeave={()=>setPrdDrpDwn(false)}>
            <DropdownToggle nav className="navbarlink">
              PRODUCTS
            </DropdownToggle>
            <DropdownMenu left>
              <DropdownItem className="dpmenu" onClick={()=>routeToPage('assets')}>
                FOURA'AI ASSETS
              </DropdownItem>
              <DropdownItem className="dpmenu" onClick={()=>routeToPage('crm')}>
                FOURA'AI CRM
              </DropdownItem>
              <DropdownItem className="dpmenu" onClick={()=>routeToPage('incidents')}>
                FOURA'AI INCIDENTS
              </DropdownItem>
              <DropdownItem className="dpmenu" onClick={()=>routeToPage('payroll')}>
                FOURA'AI PAYROLL
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <NavLink className="staticmenu" onClick={()=>routeToPage('support')}>SUPPORT</NavLink>
          <Dropdown nav inNavbar  isOpen={abtUsDrpDwn} onMouseEnter={()=>setAbtUsPrdDrpDwn(true)} 
            onMouseLeave={()=>setAbtUsPrdDrpDwn(false)}>
            <DropdownToggle nav className="navbarlink">
              ABOUT US
            </DropdownToggle>
            <DropdownMenu left>
              <DropdownItem className="dpmenu" onClick={()=>routeToPage('who-we-are')}>
                WHO WE ARE
              </DropdownItem>
              <DropdownItem className="dpmenu">
              <Link to={{
                    pathname: "/who-we-are",
                    // search: "?sort=name",
                    hash: "#start",
                    state: { fromDashboard: true }
                  }}
                >
                HOW WE STARTED</Link>
              </DropdownItem>
              <DropdownItem className="dpmenu" onClick={()=>routeToPage('partner')}>
                PARTNERS
              </DropdownItem>
              <DropdownItem onClick={()=>routeToPage('blog')} className="dpmenu">
                BLOG
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <NavLink className="staticmenu" onClick={()=>routeToPage('contact-sales')}>CONTACT SALES</NavLink>
          <NavLink className="freetrial" onClick={toggle}>FREE TRIAL</NavLink>
        </Nav>
      </Navbar>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader    toggle={toggle}>Free Trial</ModalHeader>
        <ModalBody>
           <Row className="freetrialimage">
             <Col xs={6}><img onClick={()=>{routeToPage('sign-up-assets');setModal(!modal)}
            } src={assets.default} alt="" /></Col>
             <Col xs={6}><img onClick={()=>{routeToPage('sign-up-crm');setModal(!modal)}} src={crm.default} alt="" /></Col>
           </Row>
           <Row className="freetrialimage mt-5">
             <Col xs={6}><img onClick={()=> {routeToPage('sign-up-incidents');setModal(!modal)}} src={incidents.default} alt="" /></Col>
             <Col xs={6}><img onClick={()=> {routeToPage('sign-up-payroll');setModal(!modal)}} src={payroll.default} alt="" /></Col>
           </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default Header