import React, { Fragment, useState } from 'react';
import { Carousel, CarouselItem, CarouselIndicators, Button } from 'reactstrap';
import '../../css/carousel.css';

const Payrollcarousal = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [carousel1] = useState(require("../../images/payroll-images/PAYROLL-Enterprise-1.png"));
  const [carousel2] = useState(require("../../images/payroll-images/PAYROLL-assignment-set.PNG"));
  const [carousel3] = useState(require("../../images/payroll-images/PAYROLL-definitions.png"));
  const [carousel4] = useState(require("../../images/payroll-images/PAYROLL-elements.png"));

  const items = [
    {
      src: carousel1.default,
      altText: 'Slide 1'
    },
    {
      src: carousel2.default,
      altText: 'Slide 2'
    },
    {
      src: carousel3.default,
      altText: 'Slide 3'
    },
    {
        src: carousel4.default,
        altText: 'Slide 3'
      }
  ];

  const next = () => {
    const nextIndex =1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    const nextIndex = 2;
    setActiveIndex(nextIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}>
        <img src={item.src} alt={item.altText} />
        <div className="container">
        <div className="carousel_caption">
          <span>{item.caption}</span>
        </div>
        </div>
      </CarouselItem>
    );
  });

   const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }
  return(
    <Fragment>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
      </Carousel>
    </Fragment>
  )
}

export default Payrollcarousal