import React, { Component, Fragment } from "react";
import { Card, CardHeader, CardFooter, CardBody } from "reactstrap";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import HomeScreen from './HomeScreen';
import Header from "./Header";
import Assets from "./assets/Assets";
import CRM from "./crm/crm";
import Incidents from "./incident/incidents";
import Payroll from "./payroll/payroll";
import Whoweare from "./whoweare";
import SignupAssets from "../pages/signup/signupassets";
import SignupCRM from "../pages/signup/signupcrm";
import SignupIncidents from "../pages/signup/signupincidents";
import SignupPayroll from "../pages/signup/signuppayroll";
import Partners from "../pages/partners/partners";
import "../css/footer.css";
import HomeFooter from "./HomeFooter";
import Support from "./Support";
import ContactSales from "./ContactSales";
import DemoWrapper from './bookdemo/DemoWrapper';
import BlogWrapper from "./blog/BlogWrapper";
import BlogContentWrapper from "./blog/BlogContentWrapper";

export default class HomePage extends Component {
  render() {
    return (
      <Fragment>
        <BrowserRouter>
          <Card>
            <CardHeader className="cardBg">
              <Header />
            </CardHeader>
            <CardBody>
              <Switch>
                <Route exact path="/" component={HomeScreen} />
                <Route path="/assets" component={Assets} />
                <Route path="/crm" component={CRM} />
                <Route path="/incidents" component={Incidents} />
                <Route path="/payroll" component={Payroll} />
                <Route path="/who-we-are" component={Whoweare} />
                {/* <Route path="/who-we-are/#start" component={Whoweare} /> */}
                <Route path="/support" component={Support} />
                <Route path="/contact-sales" component={ContactSales} />
                <Route path="/sign-up-assets" component={SignupAssets} />
                <Route path="/sign-up-crm" component={SignupCRM} />
                <Route path="/sign-up-incidents" component={SignupIncidents} />
                <Route path="/sign-up-payroll" component={SignupPayroll} />                
                <Route path="/partner" component={Partners} />
                <Route path="/BookDemo" component={DemoWrapper} />
				        <Route path="/blog" component={BlogWrapper} />
                <Route path="/blog-info" component={BlogContentWrapper} />
              </Switch>
              {/* <Partners /> */}
            </CardBody>
            <CardFooter className="footbg">
              <HomeFooter />
            </CardFooter>
          </Card>
        </BrowserRouter>
      </Fragment>
    );
  }
}
