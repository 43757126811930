import React, { createContext, useContext, useReducer } from 'react';
import blogReducer from '../reducer/BlogReducer';

const initialState = {blogData:[]}

const BlogStateContext = createContext(initialState);
const BlogDispatchContext = createContext(undefined);

const BlogProvider = ({children}) => {
  const [state, dispatch] = useReducer(blogReducer, initialState)
  return(
    <BlogStateContext.Provider value={state}>
      <BlogDispatchContext.Provider value={dispatch}>
        {children}
      </BlogDispatchContext.Provider>
    </BlogStateContext.Provider>
  )
}

function useBlogState() {
  const context = useContext(BlogStateContext);
  if(context === undefined){
    throw new Error('useBlogState must be used within a BlogProvider')
  }
  return context
}

function useBlogDispatch() {
  const context = useContext(BlogDispatchContext);
  if(context === undefined){
    throw new Error('useBlogDispatch must be used within a BlogProvider')
  }
  return context
}

export { BlogProvider, useBlogState, useBlogDispatch }
