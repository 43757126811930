import { Container, Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import '../index.css';

function HomeFooter() {
  let history = useHistory();
  const routeToPage = (page) => history.push(`/${page}`);

  return (
    <Container>
      <Row>
        <Col md="3" >
          <p>PRODUCTS</p>
          <p> <button className="footerBtn" onClick={()=>routeToPage('assets')}>FOURA'AI Assets</button></p>
          <p><button className="footerBtn" onClick={()=>routeToPage('crm')}>FOURA'AI CRM</button></p>
          <p> <button className="footerBtn" onClick={()=>routeToPage('incidents')}>FOURA'AI Incidents</button></p>
          <p> <button className="footerBtn" onClick={()=>routeToPage('payroll')}>FOURA'AI Payroll</button></p>
        </Col>
        <Col md="3" ><p>COMPANY</p>
          <p><button className="footerBtn" onClick={()=>routeToPage('who-we-are')}>About Us</button></p>
          <p><button className="footerBtn" onClick={()=>routeToPage('support')}>Support</button></p>
          <p><button className="footerBtn" onClick={()=>routeToPage('partner')}>Partners</button></p>
          <p><button className="footerBtn" onClick={()=>routeToPage('blog')}>Blog</button></p>
        </Col>
        <Col md="3" >

          <p>CONTACTS</p>

          <p>For Sales</p>
          <p>sathiskumar@4iapps.com</p>
          <p>Phone:+91 9486819441</p>
          <p>For Support</p>
          <p>servicedesk@4iapps.com</p>
          <p>Phone:+91 9962604080</p>
        </Col>
        <Col md="3" >  <p>FOURA'AI</p>
          <p>Locations</p>
          <p>INDIA</p>
          <p>UAE</p>
          <p>OMAN</p>
          <p>QATAR</p>
          <p>SINGAPORE</p>
          <p>MALAYSIA</p>
          <p>CANADA</p>
          <p>USA</p>
        </Col>
      </Row>
    </Container>
  );
}

export default HomeFooter;