import React, { Fragment } from "react";
import BlogLayout from './BlogLayout';
//store
import { BlogProvider } from '../../context/BlogContext';

const BlogWrapper = () => {

  return (
    <Fragment>
      <BlogProvider>
        <BlogLayout />
      </BlogProvider>
    </Fragment>
  );
};

export default BlogWrapper;
