import React, { Fragment } from "react";
import BlogContentLayout from "./BlogContentLayout";
//store
import { CommentProvider } from '../../context/CommentContext.js';

const BlogContentWrapper = (props) => {

  return (
    <Fragment>
      <CommentProvider>
        <BlogContentLayout blogId={props?.location.blogId}/>
      </CommentProvider>
    </Fragment>
  );
};

export default BlogContentWrapper;
