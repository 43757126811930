import React, { Fragment, createRef } from 'react';
import { VectorMap } from "react-jvectormap";
import { getName } from "country-list";
import './bookdemo.css';
//store
import { useDemoDispatch } from '../../context/DemoContext';
import * as actionType from '../../type';

const Location = () => {
  const mapRef = createRef();
  const dispatch = useDemoDispatch();

  const handleClick = (e, countryCode) => {
    mapRef.current.$mapObject.tip.hide(); //hide tool tip
    dispatch({type: actionType?.SELECTED_LOCATION, payload: getName(countryCode)})
  };

  return(
    <Fragment>
      <div>
      <VectorMap
        map={"world_mill"}
        ref={mapRef}
        backgroundColor="transparent" //change it to ocean blue: #0077be
        zoomOnScroll={false}
        containerStyle={{
          width: "100%",
          height: "520px"
        }}
        onRegionClick={handleClick} //gets the country code
        containerClassName="map"
        regionStyle={{
          initial: {
            fill: "#e4e4e4",
            "fill-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0
          },
          hover: {
            "fill-opacity": 1,
            fill: "#3756a6",
          },
          selected: {
            fill: "#2938bc" //color for the clicked country
          },
          selectedHover: {},
        }}
        regionsSelectable={true}
        series={{
          regions: [
            {
              // values: mapData, //this is your data
              scale: ["#146804", "#ff0000"], //your color game's here
              normalizeFunction: "polynomial"
            }
          ]
        }}
      />
    </div>
    </Fragment>
  )
}

export default Location