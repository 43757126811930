import React, { Component, Fragment } from "react";
import assetsbanner from "../../images/Assets-images/Asset-Mainpage.png";
import assetspage from "../../images/Assets-images/ASSETS_page-1.png";
import assetspage1 from "../../images/Assets-images/Asset---1.png";
import assetspage2 from "../../images/Assets-images/Asset---2.png";
import assetspage3 from "../../images/Assets-images/Asset---3.png";
import assetspage4 from "../../images/Assets-images/Asset---4.png";
import {
  Container,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Assetscarousal from "./assetscarousal";
import Pricing from '../../pages/pricingForm';
import "./assets.css";

class Assets extends Component {
  state = {
    modal: false,
  
  };

  componentDidMount(){
    window.scrollTo(0, 0);
  }
 
  pricing = ()=>{
    console.log("price invoked");
    this.setState({modal:true})
}
cancelmodal=()=>{
    console.log("cancel mo");
    this.setState({modal:false})

}

  routeToPage = (page) => this.props.history.push(`/${page}`);

  render() {
    return (
      <Fragment>
        <Row>
          <Col>
            <img src={assetsbanner} alt="" style={{width:"100%"}}/>
            <div className="carousel_caption">
              <span>Explore the all in one FOURAAI Global Assets solution</span>
              <div>
                <Button className="signupBtn" style={{backgroundColor:'#92c753', border:`1px solid #92c753`}}>
                  Sign up for Free
                </Button>
                <Button onClick={()=>this.routeToPage('BookDemo')} className="demoBtn" style={{color:'#92c753'}}>Book a demo</Button>
              </div>
            </div>
          </Col>
        </Row>
        <Container>
          <Row className="mt-10">
            <Col xs={6} md={6}>
              <h6>
                FOURA'AI Assets is user-friendly asset tracking software to
                track & manage fixed and IT assets in your organization. Make
                tracking easy and avoid unexpected penalties from audits
                forever.
              </h6>
              <b>Business Benefits using FOURA’AI Assets.</b>
              <li>
                Streamline audit operations using assets audit functionality.
              </li>
              <li>Increase team productivity by automation.</li>
              <li>
                Forecast Spending & Budgets by generating valuable reports.
              </li>
              <li>Real-Time Asset tracking gives you a clear visibility.</li>
              <li>Ensure Accuracy while tracking your assets.</li>
            </Col>
            <Col xs={6} md={6}>
              <img style={{ width: "100%" }} src={assetspage} alt="" />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={3}></Col>
            <Col md={6}>
              <h3>FOURA’AI Assets - Key Features</h3>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col xs={6} md={6}>
              <img style={{ width: "100%" }} src={assetspage1} alt="" />
            </Col>
            <Col xs={6} md={6} className="mt-5">
              <div className="fieldsset icon">
                <i class="fa fa-dashcube" />
                <span className="assettitle">Dashboard</span>
                <h6>
                  An attractive dashboard helps to keep count of assets in stock
                  using pie charts & Graphs.
                </h6>
              </div>
              <div className="fieldsset icon">
                <i class="fa fa-trello" />
                <span className="assettitle">Report generation</span>
                <h6>
                  Generate reports quickly using different filters like asset
                  ID, serial number, employee ID, model category, etc. Download
                  the generated reports in any format & share them instantly via
                  mail.
                </h6>
              </div>
              <div className="fieldsset icon">
                <i class="fa fa-random" />
                <span className="assettitle">
                  Automatic software upgrade
                </span>
                <h6>
                  Product features will be updated every year to offer the best
                  products to customers. Newly added features will be
                  automatically updated in your module.
                </h6>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={6} className="mt-10">
              <div className="fieldsset icon">
                <i class="fa fa-bandcamp" />
                <span className="assettitle">Asset Audit</span>
                <h6>
                  During audits, easily track different types of assets with
                  their value, invoice number, invoice date, sales receipts,
                  etc.
                </h6>
              </div>
              <div className="fieldsset icon">
                <i class="fa fa-weibo" />
                <span className="assettitle">Asset tracker</span>
                <h6>
                  Efficiently track asset details such as asset ID, asset value,
                  asset owner, warranty number, warranty period using asset
                  tracker.
                </h6>
              </div>
              <div className="fieldsset icon">
                <i class="fa fa-archive" />
                <span className="assettitle">Asset scrapped</span>
                <h6>
                  Reuse electrical components from scrapped assets and track
                  specific scrap information accurately.
                </h6>
              </div>
            </Col>
            <Col xs={6} md={6}>
              <img style={{ width: "100%" }} src={assetspage2} alt="" />
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={6}>
              <img style={{ width: "100%" }} src={assetspage3} alt="" />
            </Col>
            <Col xs={6} md={6} className="mt-5">
              <div className="fieldsset icon">
                <i class="fa fa-id-badge" />
                <span className="assettitle">Employee ID Mapping</span>
                <h6>
                  Effortlessly track assets by mapping employee ID numbers with
                  their assets used in the long run.
                </h6>
              </div>
              <div className="fieldsset icon">
                <i class="fa fa-bell-o" />
                <span className="assettitle">Notifications</span>
                <h6>
                  Alert notifications will be triggered automatically for Asset
                  creations and asset retirement functionality.
                </h6>
              </div>
              <div className="fieldsset icon">
                <i class="fa fa-qrcode" />
                <span className="assettitle">Asset scanner</span>
                <h6>
                  Scan your assets using a barcode scanner mapped with Asset
                  Application.
                </h6>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={6} className="mt-5">
              <div className="fieldsset icon">
                <i class="fa fa-building-o" />
                <span className="assettitle">API Integration</span>
                <h6>
                  Seamlessly integrate FOURA’AI Assets with current software.
                  Integrations are possible with any software as per your
                  requirement.
                </h6>
              </div>
              <div className="fieldsset icon">
                <i class="fa fa-mobile" />
                <span className="assettitle">Mobile Application</span>
                <h6>Mobile Application for IOS & Android.</h6>
              </div>
              <div className="fieldsset icon">
                <i class="fa fa-cubes" />
                <span className="assettitle">Customization</span>
                <h6>
                  Highly customizable as per your business requirements,
                  functionalities, Branding & integrations.
                </h6>
              </div>
              <div className="fieldsset icon">
                <i class="fa fa-comment-o" />
                <span className="assettitle">Branding</span>
                <h6>
                  Label your CRM application in Brand Name, brand color, and
                  Brand logo.
                </h6>
              </div>
            </Col>
            <Col xs={6} md={6}>
              <img style={{ width: "100%" }} src={assetspage4} alt="" />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="alignCenter">
              <h3>Get to know your pricing</h3>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3 alignCenter" xs={12}>
              <Button onClick={this.pricing} className="pricing">
                Pricing
              </Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={2}></Col>
            <Col md={8}>
              <Assetscarousal />
            </Col>
            <Col md={2}></Col>
          </Row>
          <Row className="mt-5 mb-5 btnAlignCenter">
            <Button
              onClick={() => this.routeToPage("sign-up-assets")}
              className="pricing"
            >
              GET STARTED
            </Button>
            <Button onClick={() => this.routeToPage("BookDemo")}
            className="bookademo">BOOK A DEMO</Button>
          </Row>
        </Container>

        <Modal isOpen={this.state.modal} toggle={this.cancelmodal}>
          <ModalHeader toggle={this.cancelmodal}>Pricing</ModalHeader>
          <ModalBody>
          <Pricing modal={this.cancelmodal} product="incidents"/>
          </ModalBody>
    
        </Modal>
      </Fragment>
    );
  }
}
export default Assets;
