// import socketApi from '../config/socketApi';
// import { socketConfig } from "../config/socketApi";
export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    request.headers = {
      authorization: `Bearer ${sessionStorage.getItem("accesstoken")}`,
    };
  }
  return request;
};

// export const errorHandler = async (error) => {
//   if (isHandlerEnabled(error.config)) {
//     console.log('error', error);
//     const originalRequest = error.config;
//     // Handle errors
//     if(error.response.data.error==="invalid_token" && !originalRequest.isRetryAttempt ){
//       const config = {
//         p_client_id: "ooredoo",	
//         p_refresh_token : sessionStorage.getItem('refreshToken')
//       }
//       try {
//         let result = await socketApi.post(socketConfig.refreshToken,config,{ handlerEnabled: false })
//         console.log('result', result);
//          sessionStorage.setItem('accessToken',result.data.access_token);
//          sessionStorage.setItem('refreshToken',result.data.refresh_token);
//         //  return socketApi(error.config)
//         originalRequest.headers = {
//           authorization : `Bearer ${result.data.access_token}`
//         }
//         originalRequest.isRetryAttempt = true;
//         return socketApi(originalRequest) ;
//       } catch (error) {
//         console.log('error', error);
//       }
//     }
//   }
//   return Promise.reject({ ...error });
// };

export const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }
  return response;
};