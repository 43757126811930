import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
//components
import BlogList from "./BlogList";
import RecentPosts from "./RecentPosts";
//api
import { apiConfig } from "../../services/config/apiConfig";
import api from "../../services/config/api";
//store
import { useBlogDispatch } from '../../context/BlogContext';
import * as actionType from '../../type';

const BlogLayout = () => {
  const dispatch = useBlogDispatch();
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    getBlogPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBlogPosts = async () => {
    setSpinner(true);
    try {
      let result = await api.post(apiConfig.getBlog, "", {handlerEnabled: false});
      dispatch({type: actionType?.GET_BLOG_LIST, payload: result?.data});
      setSpinner(false);
    } catch (error) {
      console.log('error', error)
      setSpinner(false);
    }
  };

  return (
    <Fragment>
      {spinner ? <Spinner />: null}
      <Container>
        <Row>
          <Col xs={12} md={8}>
            <BlogList />
          </Col>
          <Col xs={12} md={4}>
            <RecentPosts />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BlogLayout;
