import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
//store
import { useBlogState } from '../../context/BlogContext';

const RecentPosts = () => {
  const history = useHistory();
  const { blogData } = useBlogState();

  const readFurther = (data) => {
    history.push({pathname:'/blog-info', blogId: data?._id})
  }

  return(
    <Fragment>
      <div className="blogheader mb-2">Recent Posts</div>
      <div>
        {
          blogData.map(data => <div className="listItem pointer" onClick={()=>readFurther(data)}>
            <div style={{flex:1}}>{data?.title}</div>
            <div><i class="fa fa-angle-right" aria-hidden="true"></i></div></div>)
        }
      </div>
    </Fragment>
  )
}

export default RecentPosts