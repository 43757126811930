import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import BlogOverview from './BlogOverview';
//store
import { useBlogState } from '../../context/BlogContext';

const BlogList = () => {
  const history = useHistory();
  const { blogData } = useBlogState();

  const readFurther = (data) => {
    history.push({pathname:'/blog-info', blogId: data?._id})
  }
  return(
    <Fragment>
      {/* {spinner? <Spinner style={{ width: '3rem', height: '3rem' }} />: null} */}
      {blogData.map(data=> {
        return(
          <Container>
            <BlogOverview data={data} readFurther={readFurther}/>
          </Container>
        )
      })}
    </Fragment> 
  )
}

export default BlogList