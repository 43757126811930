import React, { Fragment, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from "reactstrap";
import Location from "./Location";
import AppointmentDate from "./AppointmentDate";
import PersonalInfo from "./PersonalInfo";
//store
import { useDemoState } from "../../context/DemoContext";

const BookDemo = () => {
  const { location, bookedDemo } = useDemoState();
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const goHome = () => history.push('/');

  return(
    <Fragment>
      {!location ? <Location />:
      <Container style={{marginBottom:"30px"}}>
        <Row>
          {!bookedDemo ? <Fragment><Col xs={12} sm={12} md={12} lg={6}>
            <AppointmentDate />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} className="marginForMd">
            <PersonalInfo />
          </Col></Fragment>:
          <Col xs={12}>
            {/* <div className="thanks">Thank you for a booking a demo with us.</div>
            <div style={{fontSize: "18px"}}>Your demo is booked on</div>
            <div className="dateBook">{date}
            <span style={{fontSize: "20px"}}> @ {time} {moment().tz(moment.tz.guess()).format('z')}</span></div>
            <Button className="homeBtn mt-2" onClick={goHome}>Home</Button> */}
            <div className="thanks">Thank you for a booking a demo with us. We will get in touch with you shortly!</div>
            <Button className="homeBtn mt-2" onClick={goHome}>Home</Button>
          </Col>}
        </Row>
      </Container>}
    </Fragment>
  )
}

export default BookDemo