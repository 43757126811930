import * as actionType from '../type';

const blogReducer = (state, action) => {
  switch(action?.type){
    case actionType?.GET_BLOG_LIST:{
      return{
        ...state,
        blogData: action?.payload
      }
    }
    default:
      return state
  }
}

export default blogReducer