import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import whobanner from "../images/who-images/About-us-Main.png";
import iafamily from "../images/who-images/family.jpg";
import { Container, Button, Row, Col } from "reactstrap";
import "../css/whoweare.css";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

class Whoweare extends Component {
  state = {
    model: false,
  };

  componentDidMount(){
    window.scrollTo(0, 0);
    if(this.props.location.hash){
      const anchor = document.querySelector('#start')
      anchor.scrollIntoView({ behavior: 'smooth', top: 0})
    }
  }

  componentDidUpdate(){
    if(this.props.location.hash){
      const anchor = document.querySelector('#start')
      anchor.scrollIntoView({ behavior: 'smooth', top: 0})
    }
  }

  pricing = () => {
    this.setState({ model: true });
  };
  render() {
    return (
      <Fragment>
        <Row>
          <Col>
            <img src={whobanner} alt="" style={{ width: "100%" }} />
          </Col>
        </Row>
        <Container>
          <Row className="mt-5">
            <Col md={4}></Col>
            <Col md={4} className="alignCenter">
              <h3 className="whoweare">WHO WE ARE</h3>
            </Col>
            <Col md={4}></Col>
          </Row>
          <Row className="story">
            <Col className="mt-5" md={12}>
              <h4>
                <a href="https://www.4iapps.com">4i Apps</a> was established in
                2008 with a vision to help businesses of any size to prepare,
                strategize, and implement digital solutions in ERP and
                Analytics. Our experienced IT consultants have a unique blend of
                functional & operational knowledge combined with technical
                expertise & result-oriented management experience ranging from
                Application Development to end to end IT Implementation
                projects. 
              </h4>
              <h4>With years of experience in the industry, we felt the
                need to create a product that is affordable and scalable to
                cater to small and medium scale companies. Using our technology
                acumen, we built solutions that led to the launch of FOURA’AI
                SUITE in 2017. FOURA’AI Suite consists of FOURA’AI CRM, FOURA’AI
                Payroll, FOURA’AI Incidents & FOURA’AI Assets. FOURA’AI
                Applications are low cost, user friendly, flexible &
                customizable.</h4>
            </Col>
          </Row>
          <Row className="mt-5" id="start">
            <Col md={4}></Col>
            <Col md={4} className="alignCenter">
              <h3>HOW WE STARTED</h3>
            </Col>
            <Col md={4}></Col>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work whoicon"
                  date="2008"
                  contentStyle={{ background: "rgb(33, 150, 243)" }}
                  iconStyle={{ background: "rgb(227, 94, 101)", color: "#fff" }}
                  icon={<i class="fa fa-dashcube iconchange" />}
                >
                  <p>
                    4i Apps was started when Founder Saravanan Murugesan conveyed
                    his dream to colleagues and together they built the dream
                    into reality. From 2008 there is no turning back for 4iapps.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="2015"
                  contentStyle={{ background: "rgb(33, 150, 243)" }}
                  iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                  icon={<i class="fa fa-dashcube iconchange" />}
                >
                  <h7 className="vertical-timeline-element-title">
                    216 employees,500 customers
                  </h7>
                  {/*<h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4> */}
                  <p>Commenced office in Singapore.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="2017"
                  contentStyle={{ background: "rgb(33, 150, 243)" }}
                  iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                  icon={<i class="fa fa-dashcube iconchange" />}
                >
                  <h7 className="vertical-timeline-element-title">
                    307 employees,700 customers
                  </h7>
                  {/* <h3 className="vertical-timeline-element-title">Web Designer</h3>
                                        <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4> */}
                  <p>
                    Management felt the need to develop its product suite of
                    which are user friendly with advanced features at an
                    affordable cost. The cloud suite of Products was named
                    FOURA’AI.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="2018"
                  contentStyle={{ background: "rgb(33, 150, 243)" }}
                  iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                  icon={<i class="fa fa-dashcube iconchange" />}
                >
                  {/* <h3 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social Media</h3>
                                        <h4 className="vertical-timeline-element-subtitle">Online Course</h4> */}
                  <p>FOURA’AI CRM & FOURA’AI Incidents was launched.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="2019"
                  contentStyle={{ background: "rgb(33, 150, 243)" }}
                  iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                  icon={<i class="fa fa-dashcube iconchange" />}
                >
                  {/* <h3 className="vertical-timeline-element-title">Agile Development Scrum Master</h3>
                                        <h4 className="vertical-timeline-element-subtitle">Certification</h4> */}
                  <p>FOURA’AI Assets was launched in the market.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="2020"
                  contentStyle={{ background: "rgb(33, 150, 243)" }}
                  iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                  icon={<i class="fa fa-dashcube iconchange" />}
                >
                  {/* <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital Media Visual Imaging</h3>
                                        <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4> */}
                  <p>FOURA’AI Payroll was successfully launched.</p>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </Col>
          </Row>
          <Row className="titletag">
            <Col xs={12}>
              <h3>
                4i Apps family now has 360 employees with 2000 Plus customers
                globally and will continue to grow as a business & Organization
              </h3>
            </Col>
          </Row>
        </Container>
        <Row className="mt-5">
          <Col>
            <img style={{ width: "100%" }} src={iafamily} alt="" />
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default withRouter(Whoweare);
