import React, { Fragment, useEffect, useState } from "react";
import { Container, Spinner } from "reactstrap";
import moment from 'moment';
//api
import { apiConfig } from "../../services/config/apiConfig";
import api from "../../services/config/api";
//store
import { useCommentState } from '../../context/CommentContext';

const BlogContent = (props) => {
  const [content, setContent] = useState({});
  const [spinner, setSpinner] = useState(false);
  const { commentData } = useCommentState();
  useEffect(() => {
    window.scrollTo(0, 0)
    getBlogInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBlogInfo = async () => {
    setSpinner(true);
    let blogId = {
      "_id": props?.blogId
    }
    try {
      let result = await api.post(apiConfig.getBlog, blogId, {
        handlerEnabled: false,
      });
      setContent(result?.data[0])
      setSpinner(false);
    } catch (error) {
      console.log('error', error)
      setSpinner(false);
    }
  };

  return(
    <Fragment>
      {spinner ? <Spinner />: null}
      <Container>
        <img style={{width:'100%', height:'400px'}} src={`data:image/jpeg;base64,${content?.image?.parent}`} alt="assets" />
        <div className="blogheader mt-4">{content?.title}</div>
        <div className="blogSubheader mb-3">
          <span><i class="fa fa-user" aria-hidden="true"></i></span>
          <span>{`Posted By ${content?.admin}`}</span>
          <span><i class="fa fa-calendar" aria-hidden="true"></i></span>
          <span>{moment(content?.created_at).format('MMM DD, YYYY')}</span>
          <span><i class="fa fa-comments-o" aria-hidden="true"></i></span>
          <span>{`${commentData.length} Comments`}</span>
        </div>
        <div className="blogDesc">
          {content?.description}
        </div>
      </Container>
    </Fragment>
  )
}

export default BlogContent