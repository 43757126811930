import * as actionType from '../type';

const demoReducer = (state, action) => {
  switch(action?.type){
    case actionType?.SELECTED_LOCATION:{
      return{
        ...state,
        location: action?.payload
      }
    }
    case actionType?.SET_DATE:{
      return{
        ...state,
        date: action?.payload
      }
    }
    case actionType?.SET_TIME:{
      return{
        ...state,
        time: action?.payload
      }
    }
    case actionType?.BOOKED_DEMO:{
      return{
        ...state,
        bookedDemo: action?.payload
      }
    }
    default:
      return state
  }
}

export default demoReducer